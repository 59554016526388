/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */


    // FEATURED ARTICLE - block-1 *******************************************
    .block-1{
        a{
            text-decoration: none !important;
        }

        cursor: pointer;

        .img-wrapper{
            display:block;
            padding:0;
            margin-left: -$gutter;
            margin-right: -$gutter;

            img{
                display: block;
                width: 100%;
            }
            
            &::after {
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                content: ' ';

                background: -moz-linear-gradient(top, rgba(0,0,0,0) 20%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.6) 100%);
                background: -webkit-linear-gradient(top, rgba(0,0,0,0) 20%,rgba(0,0,0,0.1) 50%,rgba(0,0,0,0.6) 100%);
                background: linear-gradient(to bottom, rgba(0,0,0,0) 20%,rgba(0,0,0,0.1) 50%,rgba(0,0,0,0.6) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#66000000',GradientType=0 );
                
            }
        }
        .wrapper{
            cursor: pointer;

            .bookmark{
                position: absolute;
                top: -1px;
                right: 3%;
                color: $primary;
            }

            .overlay-top{
                position: absolute;
                top:0;
                left:0;
                width: 80%;

                img{
                    width: 30%;
                    min-width: 80px;
                }
                
                .tag {
                    position: absolute;
                    top: 0;
                    left: 5%;
                    padding: 0.1em 0.6em;
                    font-family: 'Oswald', sans-serif;
                    text-transform: uppercase;

                    &.gray{
                        background: $gray-600;
                        color: $white;
                        font-weight: 200;
                        left: 0!important;
                        padding-left: 5%;
                    }

                    &.green{
                        background: $secondary;
                        color: $white;
                        font-weight: 400;

                        &:after {
                            position: absolute;
                            left: $gutter * 2;
                            bottom: 0;
                            transform: translateY(100%);
                            display: block;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 13px 25px 0 0;
                            border-color: $secondary transparent transparent transparent;
                            content: "";
                        }
                    }
                }
                .author{
                    font-family: 'Playfair Display', serif;
                    font-size: 1em;
                    font-weight: 400;
                    color: $white;
                    padding: 0em 3% 0.5em 5%;
                }
            }

            .overlay-bottom{
                position: absolute;
                bottom: 2em;
                left: 0;
                width: 80%;

                .clap-counter{
                    padding: 0.05em 3% 0.05em 5%;
                    font-family: 'Oswald', sans-serif;
                    font-weight: 400;
                    color: $white;
                }
        
                .category{
                    padding: 0.05em 3% 0.05em 5%;
                    font-family: 'Oswald', sans-serif;
                    font-weight: 400;
                    color: $white;
                    text-transform: uppercase;
                    margin-bottom: -1px;
                }
        
                .title{
                    padding: 0.05em 3% 0.05em 5%;
                    font-family: 'Oswald', sans-serif;
                    line-height: 1.2em;
                    font-weight: 400;
                    color: $white;
                    text-transform: uppercase;
                }

                &.gray{
                    .clap-counter{
                        padding: 0.2em 3% 0.2em 5% !important;
                    }
                    .category{
                        background: $gray-600;
                        padding: 0.2em 3% 0.2em 5% !important;
                    }
                    .title{
                        background: $gray-600;
                        padding: 0.2em 3% 0.2em 5% !important;
                    }
                }
            }

            &.big{
                .title{
                    font-size: 6em !important;
                }
            }
        }
    }






    // BIG ARTICLE - block-2 *******************************************
    .block-2{
        a{
            text-decoration: none !important;
        }

        cursor: pointer;
        background: $white;
      
        .img-wrapper{
            min-height: 35px;

            img{
                display: block;
                width: 100%;
            }
        }

        .bookmark{
            position: absolute;
            top: -1px;
            right: 10%;
            color: $primary;
        }

        .overlay-top{
            position: absolute;
            top:0;
            width: 60%;

            img{
                width: 30%;
                min-width: 80px;
            }
            
            .tag {
                position: absolute;
                top: 0;
                left: 5%;
                padding: 5px 10px;
                font-family: 'Oswald', sans-serif;
                text-transform: uppercase;

                &.gray{
                    background: $gray-600;
                    color: $white;
                    font-weight: 200;
                    left: 0!important;
                    padding-left: 5%;
                }

                &.green{
                    background: $secondary;
                    color: $white;
                    font-weight: 400;

                    &:after {
                        position: absolute;
                        left: $gutter * 2;
                        bottom: 0;
                        transform: translateY(100%);
                        display: block;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 13px 25px 0 0;
                        border-color: $secondary transparent transparent transparent;
                        content: "";
                    }
                }
            }
        }

        .overlay-bottom{
            position: relative;
            width: 80%;
            top: -1.8em;

            .author{
                font-family: 'Playfair Display', serif;
                font-size: 0.8em;
                font-weight: 400;
                color: $gray-600;
                padding: 0.5em 3% 0.5em 5%;
            }

            .clap-counter{
                padding: 0.2em 3% 0.2em 5%;
                font-family: 'Oswald', sans-serif;
                font-size: 1.2em;
                font-weight: 400;
                color: $gray-400;
            }
    
            .category{
                height: 3em;
                padding: 0.2em 3% 0.5em 5%;
                font-family: 'Oswald', sans-serif;
                font-size: 1em;
                font-weight: 400;
                color: $gray-600;
                text-transform: uppercase;
                margin-bottom: -1px;
                background: $white;
                min-width: 50px;

                &:empty{
                    background: transparent !important;
                }
            }
    
            .title{
                padding: 0.2em 3% 0.2em 5%;
                font-family: 'Oswald', sans-serif;
                font-size: 1.5em;
                line-height: 1.2em;
                font-weight: 400;
                color: $gray-600;
                text-transform: uppercase;
            }

            &.big{
                .title{
                    font-size: 6em !important;
                }
            }
        }


        // gray BG
        &.gray{
            background: $gray-100 !important;

            .category{
                background: $gray-100 !important;

                &:empty{
                    background: transparent !important;
                }
            }
        }
    }



    // SMALL ARTICLE - block-3 *******************************************
    .block-3{
        a{
            text-decoration: none !important;
        }

        cursor: pointer;

        .left{
            .img{
                display: block;
                width: 100%;
            }

            .bookmark{
                position: absolute;
                top: -1px;
                right: 5%;
                color: $primary;
            }

            .overlay-left{
                position: absolute;
                bottom: 0;
                left:0;
                width:100%;

                img{
                    width: 45%;
                    min-width: 60px;
                }

                .tag {
                    position: absolute;
                    bottom: 0;
                    padding: 5px 10px;
                    font-family: 'Oswald', sans-serif;
                    text-transform: uppercase;
                    
                    &.gray{
                        background: $gray-600;
                        color: $white;
                        font-weight: 200;
                    }
                    
                    &.green{
                        background: $secondary;
                        color: $white;
                        font-weight: 400;
                    }
                }
            }
        }
        .right{
            background: $white;
            padding: 3%;

            .author{
                position: absolute;
                bottom: 1em;
                width: 90%;
                font-family: 'Playfair Display', serif;
                font-size: 0.8em;
                font-weight: 400;
                color: $gray-600;
            }
    
            .title{
                display: block;
                position: absolute;
                top: 1em;
                width: 90%;
                font-family: 'Oswald', sans-serif;
                // font-size: 1em;
                line-height: 1em;
                font-weight: 400;
                color: $gray-600;
                text-transform: uppercase;
            }
        }

        // gray BG
        &.gray{
            .right{
                background: $gray-100 !important;
            }
        }
    }





// ARTICLE HOVER EFFECT
.block-1,
.block-2,
.block-3{
    cursor: pointer;
    .img-wrapper{
        overflow: hidden;
        .img{
            transition: all $speed cubic-bezier(0,0.3,.5,1) !important;
        }
    }
    &:hover{
        .img-wrapper .img{
            transform: scale(1.1) !important;
            opacity: 0.7 !important;
        }
    }
}






    

// MEDIA QUERYS *************************************************
@include media-breakpoint-up(xs) {
    // FEATURED ARTICLE - block-1
    .block-1{
        .wrapper{
            .overlay-top{
                .tag {
                    font-size: 1.2em;
                }
                .author{
                    font-size: 0.8em;
                }
            }
    
            .overlay-bottom{
                width: 95%;
                bottom: 1em;

                .clap-counter,
                .category{
                    font-size: 0.8em;
                }
        
                .title{
                    font-size: 1.2em;
                }
            }

            &.big{
                .title{
                    font-size: 2em !important;
                }
            }
        }
    }

    // BIG ARTICLE - block-2
    .block-2{

        .overlay-top{
            .tag {
                font-size: 2em;
            }
            .author{
                font-size: 1.2em;
            }
        }

        .overlay-bottom{
            width: 95%;
            top: -2em;

            .clap-counter,
            .category,
            .author{
                font-size: 1.2em;
            }
    
            .title{
                font-size: 2em;
            }
        }
    }

    // SMALL ARTICLE - block-3
    .block-3{
        .left{
            .overlay-left{
                .tag {
                    padding: 5px 10px;
                    font-size: 1.5em;
                }
            }
        }
        .right{
            .author{
                font-size: 1em;
            }

            .title{
                font-size: 1.3em;
            }
        }
    }
}


@include media-breakpoint-up(sm) {
    // FEATURED ARTICLE - block-1
    .block-1{
        .wrapper{
            .overlay-top{
                .tag {
                    font-size: 1.8em;
                }
                .author{
                    font-size: 1em;
                }
            }
    
            .overlay-bottom{
                width: 80%;
                bottom: 2em;

                .clap-counter,
                .category{
                    font-size: 1em;
                }
        
                .title{
                    font-size: 1.8em;
                }
            }

            &.big{
                .title{
                    font-size: 2.8em !important;
                }
            }
        }
    }

    // BIG ARTICLE - block-2
    .block-2{

        .overlay-top{
            .tag {
                font-size: 2em;
            }
            .author{
                font-size: 1em;
            }
        }

        .overlay-bottom{
            width: 95%;
            top: -1.9em;

            .clap-counter,
            .category,
            .author{
                font-size: 1em;
            }
    
            .title{
                font-size: 2em;
            }
        }
    }

    // SMALL ARTICLE - block-3
    .block-3{
        .left{
            .overlay-left{
                .tag {
                    padding: 5px 10px;
                    font-size: 1em;
                }
            }
        }
        .right{
            .author{
                font-size: 0.8em;
            }

            .title{
                font-size: 1.8em;
            }
        }
    }
}


@include media-breakpoint-up(md) {
    // FEATURED ARTICLE - block-1
    .block-1{
        .wrapper{
            .overlay-top{
                .tag {
                    font-size: 1.2em;
                }
                .author{
                    font-size: 0.8em;
                }
            }
    
            .overlay-bottom{
                width: 90%;
                bottom: 1.2em;

                .clap-counter,
                .category{
                    font-size: 0.8em;
                }
        
                .title{
                    font-size: 1.2em;
                }
            }

            &.big{
                .title{
                    font-size: 2em !important;
                }
            }
        }
    }

    // BIG ARTICLE - block-2
    .block-2{

        .overlay-top{
            .tag {
                font-size: 1.2em;
            }
            .author{
                font-size: 0.8em;
            }
        }

        .overlay-bottom{
            width: 95%;
            top: -1.5em;

            .clap-counter,
            .category,
            .author{
                font-size: 0.8em;
            }
    
            .title{
                font-size: 1.2em;
            }
        }
    }

    // SMALL ARTICLE - block-3
    .block-3{
        .left{
            .overlay-left{
                .tag {
                    padding: 5px 10px;
                    font-size: 1em;
                }
            }
        }
        .right{
            .author{
                font-size: 0.8em;
            }

            .title{
                font-size: 1.5em;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    // FEATURED ARTICLE - block-1
    .block-1{
        .wrapper{
            .overlay-top{
                .tag {
                    font-size: 1.5em;
                }
                .author{
                    font-size: 0.8em;
                }
            }
    
            .overlay-bottom{
                width: 85%;
                bottom: 1.5em;

                .clap-counter,
                .category{
                    font-size: 1em;
                }
        
                .title{
                    font-size: 1.5em;
                }
            }

            &.big{
                .title{
                    font-size: 2.5em !important;
                }
            }
        }
    }

    // BIG ARTICLE - block-2
    .block-2{

        .overlay-top{
            .tag {
                font-size: 1.5em;
            }
            .author{
                font-size: 0.8em;
            }
        }

        .overlay-bottom{
            width: 95%;
            top: -1.5em;

            .clap-counter,
            .category,
            .author{
                font-size: 0.8em;
            }
    
            .title{
                font-size: 1.5em;
            }
        }
    }

    // SMALL ARTICLE - block-3
    .block-3{
        .left{
            .overlay-left{
                .tag {
                    padding: 5px 10px;
                    font-size: 1.2em;
                }
            }
        }
        .right{
            .author{
                font-size: 1em;
            }

            .title{
                font-size: 1.5em;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    // FEATURED ARTICLE - block-1
    .block-1{
        .wrapper{
            .overlay-top{
                .tag {
                    font-size: 1.5em;
                }
                .author{
                    font-size: 1.2em;
                }
            }
    
            .overlay-bottom{
                width: 80%;
                bottom: 2em;

                .clap-counter,
                .category{
                    font-size: 1em;
                }
        
                .title{
                    font-size: 1.8em;
                }
            }

            &.big{
                .title{
                    font-size: 2.5em !important;
                }
            }
        }
    }

    // BIG ARTICLE - block-2
    .block-2{
        .overlay-top{
            .tag {
                font-size: 2em;
            }
            .author{
                font-size: 1.2em;
            }
        }

        .overlay-bottom{
            width: 95%;
            top: -1.8em;

            .clap-counter,
            .category,
            .author{
                font-size: 1.2em;
            }
    
            .title{
                font-size: 2em;
            }
        }
    }

    // SMALL ARTICLE - block-3
    .block-3{
        .left{
            .overlay-left{
                .tag {
                    padding: 5px 10px;
                    font-size: 1.5em;
                }
            }
        }
        .right{
            .author{
                font-size: 1.2em;
            }

            .title{
                font-size: 1.5em;
            }
        }
    }
}