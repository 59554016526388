/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */

.carousel{
    .overlay{
        position: absolute;
        left:0;
        right:0;
        z-index: 500;
        height: 50%;

        .ico-padding{
            padding-top: 6px;
            padding-right: 25px;
        }
    }
}


// .tickets{
//     .carousel{
//         .overlay{
//             position: absolute;
//             left:0;
//             right:0;
//             z-index: 500;
//             height: 50%;
//             color: red;

//             img{
//                 max-width: 80px;
//             }
//         }
//     }
// }


// MEDIA QUERYS *************************************************
@include media-breakpoint-up(xs) {

}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}