/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */



 footer {
    position: relative;
    z-index: 500;
    background-color: $gray-700;
    text-align: center;
    // margin-top: -5px;

    .footer-menu {
        .container{
            .row{
                min-height: 60px;
            }
        }
        .nav{
            &.social{
                .nav-item{
                    .nav-link{
                        font-size: 1em;
                    }
                }
            }
            .nav-item{
                .nav-link{
                    font-family: 'Montserrat', sans-serif;
                    color: $gray-400;
                    font-size: 0.8em;
                    padding: 0 1em;

                    &:hover{
                        color: $primary;
                    }
                    &.active{
                        color: $primary;
                    }
                }
            }
        }
    }

    .partners {
        font-family: 'Montserrat', sans-serif;
        color: $gray-400;
        font-size: 0.8em;

        .container{
            .row{
                min-height: 50px;
            }
        }
    }

    .social{
        .container{
            .row{
                min-height: 45px;
            }
        }

        .nav{
            .nav-item{
                .nav-link{
                    font-family: 'Montserrat', sans-serif;
                    color: $gray-400;
                    font-size: 1.8em;

                    &:hover{
                        color: $primary;
                    }
                    &.active{
                        color: $primary;
                    }
                }
            }
        }
    }

    .footer-text {
        font-family: 'Montserrat', sans-serif;
        color: $gray-400;
        font-size: 0.8em;
        padding-top: 1em;
        padding-bottom: 1.5em;

        .container{
            .row{
                min-height: 60px;
            }
        }
    }

    .copyright{
        font-family: 'Montserrat', sans-serif;
        color: $gray-500;
        padding-bottom: 3em;

        font-size: 0.8em;
        color: $gray-500;

        a{
            font-size: 0.8em;
            color: $gray-500;

            &:hover{
                color: $primary;
            }
            &.active{
                color: $primary;
            }
        }
    }
}

.footer-divider {
    width: 100%;
    height: 6px;
    margin-top: -6px;
    background-image: url("../assets/img/footer-bg.png");
    background-repeat: repeat-x;
}