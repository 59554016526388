/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */

#body{
    #ticket-cart{
        background: $primary;
        font-family: 'Oswald', sans-serif;
        font-size: 1.2em;
        font-weight: 200;
        color: $gray-600;
        box-shadow: 0 0 20px 0px rgba(0,0,0,0.2), 0 10px 10px rgba(0,0,0,0.1);

        #ticketCarousel{
            .ticket{
                padding: $spacing $spacing*1.5;
            }
        }

        .cart{
            background: #440532 !important;
            padding: $spacing*0.3 $spacing*0.7;

            .in-cart{
                display: block;
                width: 100%;
                color: $primary;
                font-weight: 400;
                text-transform: uppercase;
            }
            .ticket-count{
                display: block;
                width: 100%;
                color: $white;
            }
        }

        .ticket{
            background: $primary;
            color: $white;
            border-right: 1px solid #440532;

            display:flex;
            align-items:center;

        }

        .buy{
            background: $gray-800 !important;
            min-height: 4em;

            span{
                a{
                    color: $secondary;
                    text-decoration: none;

                    &:hover{
                        color: $gray-600;
                    }
                }
            }
        }
    }
    .article-meta{
        margin-bottom: $spacing;
        font-family: 'Playfair Display', sans-serif;
        font-size: 1em;
        font-weight: 300;

        a{
            color: $gray-600 !important;
        }
    }
    #article-title{
        font-family: 'Oswald', sans-serif;
        font-size: 3em;
        font-weight: 400;
        color: $gray-600;
        text-transform: uppercase;
    }

    .abstract{
        font-family: 'Playfair Display', sans-serif;
        font-size: 1.5em;
        line-height: 2em;
        font-weight: 400;
        margin-bottom: $spacing;
    }
    p {
        font-family: 'Playfair Display', sans-serif;
        font-size: 1em;
        line-height: 2em;
        font-weight: 300;
    }


    #main{
        #content{

            // HOMEPAGE BLOCKS
            .block{
                &.p-block{
                    padding-bottom: $spacing*1.5 !important;
                }
            }

            .block-half{
                &.p-block{
                    padding-bottom: $spacing*1.5 !important;
                }
            }

            // ARTICLES *******************************************************************
            .article-header{
                // articles with heading above carousel
                &.above{
                    color: red;
                }

                // articles with heading under carousel
                &.sub{
                    .title{
                        font-family: 'Oswald', sans-serif;
                        font-size: 3em;
                        font-weight: 200;
                        color: $gray-600;
                        padding: 0.5em;
                        margin: 0px;
                        text-transform: uppercase;
                    }
                    .date-ico,
                    .venue-ico{
                        color: $white;
                        padding: 1em;
                    }
                    .date,
                    .venue{
                        font-family: 'Montserrat', sans-serif;
                        font-size: 1em;
                        font-weight: 300;
                        color: $white;
                        padding: 1em;
                        // margin: 0px;
                    }
                }
            }

            // ticket list title correction
            &.tickets{
                padding-top: 4em;
                padding-bottom: 3em;

                .ticket{
                    .article-header{
                        .title{
                            h1{
                                font-size: 0.6em !important;
                            }
                        }
                    }
                }
            }


            .article-ticket{
                margin-bottom: $spacing;
                .top{
                    font-family: 'Oswald', sans-serif;
                    font-size: 1em;
                    font-weight: 200;
                    color: $gray-600;
                    padding: 0.2em 0.8em;
                    margin: 0px; 
                    border-top: 1px solid $gray-400;
                    border-bottom: 1px solid $gray-400;
                }

                .bottom{
                    font-family: 'Oswald', sans-serif;
                    font-size: 1em;
                    font-weight: 200;
                    color: $gray-600;
                    border-top: 1px solid $gray-400;
                    border-bottom: 1px solid $gray-400;

                    .ico{
                        text-align: center;
                        padding: 1em;
                        border-right: 1px solid $gray-400;
                        width: 4em;
                        height: 4em;
                    }
                    .buy{
                        color: $secondary;
                        padding-right: 1.5em;

                        i{
                            margin-left: 0.5em;
                        }
                    }
                }
                .prices{
                    padding: $spacing*0.5;
                    .regular,
                    .mladismo,
                    .mastercard{
                        .text{
                            font-family: 'Montserrat', sans-serif;
                            font-size: 1em;
                            font-weight: 300;
                            text-align: center;
                        }
                        .price{
                            font-family: 'Oswald', sans-serif;
                            font-size: 3em;
                            font-weight: 200;
                            text-align: center;
                        }
                    }
                    .regular{
                        .price{
                            color: $gray-300;
                            text-decoration: line-through;
                        }
                    }
                    .mladismo{
                        .price{
                            color: $gray-600;
                        }
                    }
                    .mastercard{
                        .price{
                            color: $primary;
                        }
                    }
                }
            }

            .article-author{
                &.partner{
                    .follow{
                        border-color: $primary !important;
                        color: $primary !important;
                    }

                    .avatar{
                        border-color: $primary;
                    }
                }

                .social{
                    border-top: 1px solid $gray-400;
                    border-bottom: 1px solid $gray-400;

                    a{
                        color: $black;
                        text-decoration: none;
    
                        &:hover{
                            color: $gray-400;
                        }
                    }

                    i{
                        padding-left: 2em;
                    }
                }

                .avatar{
                    width: 90%;
                    border-radius: 50%;
                    text-align: center;
                    margin-bottom: $gutter;
                    padding: 5px;
                    border: 1px solid $gray-300;
                }
                .user{
                    // margin-bottom: $spacing*0.5;
                    font-family: 'Oswald', sans-serif;
                    font-size: 1.2em;
                    font-weight: 700;
                    color: $gray-600;
                }
                .meta{
                    margin-bottom: $spacing*0.5;
                    font-family: 'Playfair Display', sans-serif;
                    font-size: 1.2em;
                    font-weight: 400;
                    color: $gray-600;
                }
                .comment{
                    margin-bottom: $spacing*0.5;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 1.2em;
                    font-weight: 400;
                    color: $gray-600;
                }

                button.follow{
                    border: 1px solid $secondary;
                    background: none;
                    font-family: 'Oswald', sans-serif;
                    font-size: 1em !important;
                    line-height: 2.5em;
                    color: $secondary;
                    border-radius: 3px;
                    width: 95%;

                    &:hover{
                        background: $gray-100;;
                    }
                }
                .social-author{
                    a{
                        color: $black;
                        text-decoration: none;
    
                        &:hover{
                            color: $gray-400;
                        }
                    }
                    i{
                        padding-left: 1em;
                    }
                }
            }

            .instagram{
                .instagram-title{
                    background: $black;
                    color: $white;
                    padding: 0.5em 2em;
                    font-family: 'Oswald', sans-serif;
                    font-size: 1.5em;
                    font-weight: 400;

                    text-align: center;
                    margin:auto;

                }
            }

            .other-authors{
                border-top: 1px solid $gray-400;
                padding-top: $spacing;
                padding-bottom: $spacing;

                // author small
                .author-small{
                    .avatar{
                        width: 80%;
                        border-radius: 50%;
                        text-align: center;
                        padding: 5px;
                        border: 1px solid $gray-300;
                        max-width:120px;
                    }
                    .author-name{
                        font-family: 'Oswald', sans-serif;
                        font-size: 1.5em;
                        font-weight: 400;
                        color: $gray-600;
                        text-align: center;
                        width: 100%;
                        display: block;
                        margin-top: $spacing*0.5;
                    }
                    .author-sub{
                        font-family: 'Playfair Display', sans-serif;
                        font-size: 1.2em;
                        font-weight: 300;
                        color: $gray-600;
                        text-align: center;
                        width: 100%;
                        display: block;
                        margin-bottom: $spacing*0.5;
                    }
                    button.follow{
                        border: 1px solid $secondary;
                        background: none;
                        font-family: 'Oswald', sans-serif;
                        font-size: 1em !important;
                        line-height: 2.5em;
                        color: $secondary;
                        border-radius: 3px;
                        width: 70%;
    
                        &:hover{
                            background: $gray-100;;
                        }
                    }
                }
            }

            .article-navigation{
                border-top: 1px solid $gray-400;

                .link{
                    font-family: 'Oswald', sans-serif;
                    font-size: 1em !important;
                    line-height: 2.5em;
                    color: $secondary;
                }

                .title{
                    font-family: 'Playfair Display', sans-serif;
                    font-size: 1em;
                    font-weight: 700;
                }
                .excerpt{
                    font-family: 'Playfair Display', sans-serif;
                    font-size: 1em;
                    font-weight: 300;
                }
            }

            .article-picture,
            .article-picture-left,
            .article-picture-right{
                font-family: 'Playfair Display', sans-serif;
                font-size: 1em;
                font-weight: 300;

                .strong{
                    font-weight: 700 !important;
                }

                .overlay{
                    position: absolute;
                    top: -3em;
                    left: 5em;
                    width: 35%;
                    background: $white;
                    padding: 2em;
                    border: 1px solid $gray-400;
                }
            }

            .article-content{
                //margin-bottom: $spacing;

                h1,h2,h3,h4,h5,h6{
                    line-height: 1.5em;
                    padding-top: 0.8em;
                    font-family: 'Oswald', sans-serif;
                }

                .article-quote{
                    margin-bottom: $spacing;
                    font-family: 'Playfair Display', sans-serif;
                    font-style: italic;
                    color: $primary;
                    font-size: 1.5em;
                    line-height: 1.8em;
                    font-weight: 300;
                    padding-left: 8%;
                    padding-right: 8%;
                    padding-bottom: 1em;
                }

                .article-quote-block{
                    position: relative;
                    .left{
                        position: absolute;
                        width: 50%;
                        z-index: 5;
                        margin-bottom: 20%;

                        .quote{
                            position: absolute;
                            left: 5%;
                            right: 5%;
                            bottom: -5%;
                            background: $white;
                            border-radius: 3px;
                            padding: 5%;

                            font-family: 'Playfair Display', sans-serif;
                            font-style: italic;
                            color: #80217e;
                            font-size: 1.5em;
                            line-height: 1.8em;
                            font-weight: 300;
                        }
                    }
                    .right{
                        position: relative;
                        float:right;
                        margin-top: 10%;
                        width: 80%;
                        z-index: 1;
                    }
                }

                .media{
                    &.video{
                        //margin-top: $spacing;
                    }
                }
                .media-caption{
                    margin-bottom: $spacing;
                    .rating{
                        padding: $spacing*0.5 $spacing $spacing*0.2 $spacing;
                        color: $gray-400;

                        .active{
                            color: $secondary;
                        }
                    }
                    .title{
                        padding: $spacing*0.2 $spacing;
                        font-size: 1.5em;
                    }
                    .text{
                        padding: $spacing*0.2 $spacing $spacing*0.5 $spacing;
                    }
                }
            }

            .ticket-info{
                margin-bottom: $spacing;
                .title{
                    font-family: 'Oswald', sans-serif;
                    font-size: 1.2em;
                    font-weight: 400;
                    text-transform: uppercase;
                }
                .text{
                    font-family: 'Oswald', sans-serif;
                    font-size: 1em;
                    line-height: 1.5em;
                    font-weight: 200;
                }
            }

            .tags{
                margin-bottom: $spacing;
                .tag{
                    display: block;
                    float: left;
                    padding: 0.3em 1.8em;
                    margin-right: 1em;
                    margin-bottom: 1em;
                    background: $gray-200;
                    font-family: 'Oswald', sans-serif;
                    font-size: 1.2em;
                    font-weight: 400;
                    color: $gray-600;
                    border-radius: 4px;
                    text-transform: uppercase;
                }
            }

        }


        // COMMENTS
        .comments{
            .count{
                padding: $spacing*0.7 0 $spacing*0.5 20px;
                margin-bottom: $spacing;
                font-family: 'Montserrat', sans-serif;
                font-size: 1.2em;
                font-weight: 400;
                color: $gray-600;
                border-bottom: 1px solid $gray-600;
                max-width: 200px;
                width: 100%;
            }
            .avatar{
                margin-top: 2.5em;
                width:80%;
                border-radius: 50%;
                text-align: center;
            }
            .user{
                margin-bottom: $spacing*0.5;
                font-family: 'Montserrat', sans-serif;
                font-size: 1.2em;
                font-weight: 700;
                color: $gray-600;
            }
            .meta{
                margin-bottom: $spacing*0.5;
                font-family: 'Montserrat', sans-serif;
                font-size: 1.2em;
                font-weight: 400;
                color: $gray-400;
            }
            .comment{
                margin-bottom: $spacing*0.5;
                font-family: 'Montserrat', sans-serif;
                font-size: 1.2em;
                font-weight: 400;
                color: $gray-600;
            }
        }

        // registration success
        .registration-success{
            font-family: 'Oswald', sans-serif;
            font-size: 1.2em;
            font-weight: 300;
            color: $gray-600;
        }
    }

    // HEADINGS
    .heading{
        position:relative;
        display: block;
        font-family: 'Oswald', sans-serif;
        font-size: 2.5em;
        font-weight: 200;
        padding: 0.3em 0.6em 0.3em 20px;
        margin: 0px;
        text-transform: uppercase;

        &.small{
            font-size: 1.5em !important;
            padding-left: 2em;
        }

        &.primary{
            background-color: $primary;
            color: $white;
        }
        &.secondary{
            background-color: $gray-400;
            color: $gray-600;
            margin-bottom: $spacing;

            &:before {
                position: absolute;
                display: block;
                background-image: url("../assets/img/content-bg.png");
                background-repeat: repeat-x;
                background-position: top;

                content: "";
                bottom: 0;
                left: 0;
                transform: translateY(100%);
                width: 100%;
                height: 60px;
            }
            &:after {
                position: absolute;
                display: block;
                content: "";
                bottom: 0;
                transform: translateY(99%);
                left: $gutter * 2;
                width: 0;
                height: 60px;
                border-style: solid;
                border-width: 13px 25px 0 0;
                border-color: $gray-400 transparent transparent transparent;
            }
        }
        &.tertiary{
            background-color: $gray-700;
            color: $white;
        }
    }
}


// FLOATER
.floater{
    position: fixed;
    top: 20%;
    left: -10px;
    z-index: 1000;

    opacity: 0;
    transition: opacity 0.3s;

    .icon{
        padding: $spacing*0.5 0;
    }

    .ico-clap,
    .ico-clap-inv{
        color: $secondary;
    }
    .clap-count{
        color: $gray-500;
        margin-bottom: 1.5em;
    }

    &.show{
        opacity: 1;
    }
}

.floater-mobile{
    border-top: 1px solid $gray-300;
    .icon{
        padding: 0;
    }
    .ico-clap,
    .ico-clap-inv{
        color: $secondary;
    }
    .clap-count{
        color: $gray-500;
    }
}





// MEDIA QUERYS
@include media-breakpoint-up(xs) {
    .mobile-padding{
        padding-left: $spacing*0.5;
        padding-right: $spacing*0.5;
    }


    #body{    
        #main{
            #content{

                .article-content{
                    .article-quote-block{
                        .left{
                            .quote{
                                font-size: 0.8em;
                                line-height: 1.2em;
                            }
                        }
                    }
                }

                .block-half{
                    &:nth-child(even) {
                        padding-left: 0;
                        padding-right: 0;
                    }
                    &:nth-child(odd) {
                        padding-left:0;
                        padding-right:0;
                    }
                }
                
                .article-picture,
                .article-picture-left,
                .article-picture-right{

                    .overlay{
                        position: relative;
                        width: 75%;
                        background: $white;
                        padding: 2em;
                        border: 1px solid $gray-400;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    #body{    
        #main{
            #content{
                .article-content{
                    .article-quote-block{
                        .left{
                            .quote{
                                font-size: 1em;
                                line-height: 1.8em;
                            }
                        }
                    }
                }

                .block-half{
                    &:nth-child(even) {
                        padding-left: $spacing*0.5;
                        padding-right: 0;
                    }
                    &:nth-child(odd) {
                        padding-left:0;
                        padding-right: $spacing*0.5;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .mobile-padding{
        padding-left: 0;
        padding-right: 0;
    }
    #body{    
        #main{
            #content{
                padding-right: $spacing;

                .article-content{
                    .article-quote-block{
                        .left{
                            .quote{
                                font-size: 1em;
                            }
                        }
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    #body{    
        #main{
            #content{
                .article-content{
                    .article-quote-block{
                        .left{
                            .quote{
                                font-size: 1.5em;
                            }
                        }
                    }
                }

                .article-picture,
                .article-picture-left,
                .article-picture-right{

                    .overlay{
                        position: absolute;
                        top: -3em;
                        left: 5em;
                        width: 35%;
                        padding: 2em;
                    }
                }
            }
        }
    }

}
@include media-breakpoint-up(xl) {
    #body{    
        #main{
            #content{
                .article-content{
                    .article-quote-block{
                        .left{
                            .quote{
                                font-size: 1.8em;
                            }
                        }
                    }
                }
            }
        }
    }
}