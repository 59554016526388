@font-face {
  font-family: 'mladismo';
  src:  url('../assets/fonts/mladismo.eot');
  src:  url('../assets/fonts/mladismo.eot#iefix') format('embedded-opentype'),
    url('../assets/fonts/mladismo.ttf') format('truetype'),
    url('../assets/fonts/mladismo.woff') format('woff'),
    url('../assets/fonts/mladismo.svg#mladismo') format('svg');
  font-weight: normal;
  font-style: normal;
}

// size
$size: 1;

$ico-bookmark: \e900;
$ico-arrow-left: \e901;
$ico-arrow-right: \e902;
$ico-folder: \e903;
$ico-ticket-light: \e904;
$ico-ticket: \e905;
$ico-calendar-light: \e906;
$ico-calendar: \e907;
$ico-bag-light: \e908;
$ico-bag: \e909;
$ico-card-light: \e90a;
$ico-card: \e90b;
$ico-camera-light: \e90c;
$ico-camera: \e90d;
$ico-location-light: \e90e;
$ico-location: \e90f;
$ico-upn-light: \e910;
$ico-upn: \e911;
$ico-present-light: \e912;
$ico-present: \e913;
$ico-smiley-light: \e914;
$ico-smiley: \e915;
$ico-profile-light: \e916;
$ico-profile: \e917;
$ico-profile-circle-light: \e918;
$ico-profile-circle: \e919;
$ico-warn-light: \e91a;
$ico-warn: \e91b;
$ico-reload: \e91c;
$ico-checkbox-checked: \e91d;
$ico-checkbox-unchecked: \e91e;
$ico-radio-checked: \e91f;
$ico-radio-unchecked: \e920;
$ico-clap-inv: \e921;
$ico-clap: \e922;
$ico-check: \e923;
$ico-chevron: \e924;
$ico-menu: \e925;
$ico-info: \e926;
$ico-dish: \e927;
$ico-download: \e928;
$ico-edit: \e929;
$ico-paperclip: \e92a;
$ico-key: \e92b;
$ico-carrot: \e92c;
$ico-search: \e92d;
$ico-comment: \e92e;
$ico-plus: \e92f;
$ico-heart: \e930;
$ico-americanexpress: \e931;
$ico-bitcoin: \e932;
$ico-blogger: \e933;
$ico-buzzfeed: \e934;
$ico-deezer: \e935;
$ico-delicious: \e936;
$ico-digg: \e937;
$ico-ethereum: \e938;
$ico-facebook: \e939;
$ico-gmail: \e93a;
$ico-googlehangouts: \e93b;
$ico-googleplay: \e93c;
$ico-googleplus: \e93d;
$ico-instagram: \e93e;
$ico-kik: \e93f;
$ico-linkedin: \e940;
$ico-messenger: \e941;
$ico-monero: \e942;
$ico-myspace: \e943;
$ico-paypal: \e944;
$ico-pin: \e945;
$ico-pinterest: \e946;
$ico-reddit: \e947;
$ico-skype: \e948;
$ico-snapchat: \e949;
$ico-star: \e94a;
$ico-telegram: \e94b;
$ico-tripadvisor: \e94c;
$ico-tumblr: \e94d;
$ico-twitter: \e94e;
$ico-viber: \e94f;
$ico-vimeo: \e950;
$ico-vine: \e951;
$ico-visa: \e952;
$ico-wechat: \e953;
$ico-whatsapp: \e954;
$ico-wheelchair: \e955;
$ico-yahoo: \e956;
$ico-yelp: \e957;
$ico-youtube: \e958;


i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'mladismo' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: $size*1em;
  margin: auto;

  -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;

  &:before{
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }

  &.padding{
    padding-left: 0.3em;
    padding-right: 0.3em;
  }

  &.circled{
    border: 1px solid;
    border-radius: 50%;
    padding: $gutter*0.8;

    &.primary{
      border-color: $primary;
    }
    &.secondary{
      border-color: $secondary;
    }
    &.gray{
      border-color: $gray-400;
    }
  }


  &.ico-2x{
    font-size: $size*1.3em;
  }
  &.ico-3x{
    font-size: $size*1.6em;
  }
  &.ico-4x{
    font-size: $size*2em;
  }
  &.ico-5x{
    font-size: $size*2.5em;
  }
  &.ico-6x{
    font-size: $size*3em;
  }
  &.ico-7x{
    font-size: $size*3.5em;
  }
  &.ico-8x{
    font-size: $size*4em;
  }
  &.ico-9x{
    font-size: $size*6em;
  }
  &.ico-10x{
    font-size: $size*10em;
  }

  // icon background
  &.ico-bg{
    padding: 0.6em;
    line-height: 2.5em;
    border-radius: 50%;
    color: $white;
    background: $primary;
  }
  &.ico-bg-white{
    padding: 1em;
    line-height: 2.5em;
    border-radius: 50%;
    color: $secondary;
    background: $white;
    box-shadow: $shadow;
  }

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



@function unicode($str) {
  @return unquote("\"") + $str + unquote("\"");
}



.ico-bookmark {
  &:before {
    content: unicode($ico-bookmark); 
  }
}
.ico-arrow-left {
  &:before {
    content: unicode($ico-arrow-left); 
  }
}
.ico-arrow-right {
  &:before {
    content: unicode($ico-arrow-right); 
  }
}
.ico-folder {
  &:before {
    content: unicode($ico-folder); 
  }
}
.ico-ticket-light {
  &:before {
    content: unicode($ico-ticket-light); 
  }
}
.ico-ticket {
  &:before {
    content: unicode($ico-ticket); 
  }
}
.ico-calendar-light {
  &:before {
    content: unicode($ico-calendar-light); 
  }
}
.ico-calendar {
  &:before {
    content: unicode($ico-calendar); 
  }
}
.ico-bag-light {
  &:before {
    content: unicode($ico-bag-light); 
  }
}
.ico-bag {
  &:before {
    content: unicode($ico-bag); 
  }
}
.ico-card-light {
  &:before {
    content: unicode($ico-card-light); 
  }
}
.ico-card {
  &:before {
    content: unicode($ico-card); 
  }
}
.ico-camera-light {
  &:before {
    content: unicode($ico-camera-light); 
  }
}
.ico-camera {
  &:before {
    content: unicode($ico-camera); 
  }
}
.ico-location-light {
  &:before {
    content: unicode($ico-location-light); 
  }
}
.ico-location {
  &:before {
    content: unicode($ico-location); 
  }
}
.ico-upn-light {
  &:before {
    content: unicode($ico-upn-light); 
  }
}
.ico-upn {
  &:before {
    content: unicode($ico-upn); 
  }
}
.ico-present-light {
  &:before {
    content: unicode($ico-present-light); 
  }
}
.ico-present {
  &:before {
    content: unicode($ico-present); 
  }
}
.ico-smiley-light {
  &:before {
    content: unicode($ico-smiley-light); 
  }
}
.ico-smiley {
  &:before {
    content: unicode($ico-smiley); 
  }
}
.ico-profile-light {
  &:before {
    content: unicode($ico-profile-light); 
  }
}
.ico-profile {
  &:before {
    content: unicode($ico-profile); 
  }
}
.ico-profile-circle-light {
  &:before {
    content: unicode($ico-profile-circle-light); 
  }
}
.ico-profile-circle {
  &:before {
    content: unicode($ico-profile-circle); 
  }
}
.ico-warn-light {
  &:before {
    content: unicode($ico-warn-light); 
  }
}
.ico-warn {
  &:before {
    content: unicode($ico-warn); 
  }
}
.ico-reload {
  &:before {
    content: unicode($ico-reload); 
  }
}
.ico-checkbox-checked {
  &:before {
    content: unicode($ico-checkbox-checked); 
  }
}
.ico-checkbox-unchecked {
  &:before {
    content: unicode($ico-checkbox-unchecked); 
  }
}
.ico-radio-checked {
  &:before {
    content: unicode($ico-radio-checked); 
  }
}
.ico-radio-unchecked {
  &:before {
    content: unicode($ico-radio-unchecked); 
  }
}
.ico-clap-inv {
  &:before {
    content: unicode($ico-clap-inv); 
  }
}
.ico-clap {
  &:before {
    content: unicode($ico-clap); 
  }
}
.ico-check {
  &:before {
    content: unicode($ico-check); 
  }
}
.ico-chevron {
  &:before {
    content: unicode($ico-chevron); 
  }
}
.ico-menu {
  &:before {
    content: unicode($ico-menu); 
  }
}
.ico-info {
  &:before {
    content: unicode($ico-info); 
  }
}
.ico-dish {
  &:before {
    content: unicode($ico-dish); 
  }
}
.ico-download {
  &:before {
    content: unicode($ico-download); 
  }
}
.ico-edit {
  &:before {
    content: unicode($ico-edit); 
  }
}
.ico-paperclip {
  &:before {
    content: unicode($ico-paperclip); 
  }
}
.ico-key {
  &:before {
    content: unicode($ico-key); 
  }
}
.ico-carrot {
  &:before {
    content: unicode($ico-carrot); 
  }
}
.ico-search {
  &:before {
    content: unicode($ico-search); 
  }
}
.ico-comment {
  &:before {
    content: unicode($ico-comment); 
  }
}
.ico-plus {
  &:before {
    content: unicode($ico-plus); 
  }
}
.ico-heart {
  &:before {
    content: unicode($ico-heart); 
  }
}
.ico-americanexpress {
  &:before {
    content: unicode($ico-americanexpress); 
  }
}
.ico-bitcoin {
  &:before {
    content: unicode($ico-bitcoin); 
  }
}
.ico-blogger {
  &:before {
    content: unicode($ico-blogger); 
  }
}
.ico-buzzfeed {
  &:before {
    content: unicode($ico-buzzfeed); 
  }
}
.ico-deezer {
  &:before {
    content: unicode($ico-deezer); 
  }
}
.ico-delicious {
  &:before {
    content: unicode($ico-delicious); 
  }
}
.ico-digg {
  &:before {
    content: unicode($ico-digg); 
  }
}
.ico-ethereum {
  &:before {
    content: unicode($ico-ethereum); 
  }
}
.ico-facebook {
  &:before {
    content: unicode($ico-facebook); 
  }
}
.ico-gmail {
  &:before {
    content: unicode($ico-gmail); 
  }
}
.ico-googlehangouts {
  &:before {
    content: unicode($ico-googlehangouts); 
  }
}
.ico-googleplay {
  &:before {
    content: unicode($ico-googleplay); 
  }
}
.ico-googleplus {
  &:before {
    content: unicode($ico-googleplus); 
  }
}
.ico-instagram {
  &:before {
    content: unicode($ico-instagram); 
  }
}
.ico-kik {
  &:before {
    content: unicode($ico-kik); 
  }
}
.ico-linkedin {
  &:before {
    content: unicode($ico-linkedin); 
  }
}
.ico-messenger {
  &:before {
    content: unicode($ico-messenger); 
  }
}
.ico-monero {
  &:before {
    content: unicode($ico-monero); 
  }
}
.ico-myspace {
  &:before {
    content: unicode($ico-myspace); 
  }
}
.ico-paypal {
  &:before {
    content: unicode($ico-paypal); 
  }
}
.ico-pin {
  &:before {
    content: unicode($ico-pin); 
  }
}
.ico-pinterest {
  &:before {
    content: unicode($ico-pinterest); 
  }
}
.ico-reddit {
  &:before {
    content: unicode($ico-reddit); 
  }
}
.ico-skype {
  &:before {
    content: unicode($ico-skype); 
  }
}
.ico-snapchat {
  &:before {
    content: unicode($ico-snapchat); 
  }
}
.ico-star {
  &:before {
    content: unicode($ico-star); 
  }
}
.ico-telegram {
  &:before {
    content: unicode($ico-telegram); 
  }
}
.ico-tripadvisor {
  &:before {
    content: unicode($ico-tripadvisor); 
  }
}
.ico-tumblr {
  &:before {
    content: unicode($ico-tumblr); 
  }
}
.ico-twitter {
  &:before {
    content: unicode($ico-twitter); 
  }
}
.ico-viber {
  &:before {
    content: unicode($ico-viber); 
  }
}
.ico-vimeo {
  &:before {
    content: unicode($ico-vimeo); 
  }
}
.ico-vine {
  &:before {
    content: unicode($ico-vine); 
  }
}
.ico-visa {
  &:before {
    content: unicode($ico-visa); 
  }
}
.ico-wechat {
  &:before {
    content: unicode($ico-wechat); 
  }
}
.ico-whatsapp {
  &:before {
    content: unicode($ico-whatsapp); 
  }
}
.ico-wheelchair {
  &:before {
    content: unicode($ico-wheelchair); 
  }
}
.ico-yahoo {
  &:before {
    content: unicode($ico-yahoo); 
  }
}
.ico-yelp {
  &:before {
    content: unicode($ico-yelp); 
  }
}
.ico-youtube {
  &:before {
    content: unicode($ico-youtube); 
  }
}