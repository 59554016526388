/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */


.e-news{
    &.big{
        .container{
            background: $gray-100;
            padding: $spacing ;

            .top-line{
                font-family: 'Oswald', sans-serif;
                color: $secondary;
                font-size: 1.5em;
                font-weight: 200;
            }

            .bottom-line{
                font-family: 'Playfair Display', sans-serif;
                color: $gray-600;
                font-size: 2.5em;
                font-weight: 700;
                line-height: 1.2em;

                span{
                    font-family: 'Playfair Display', sans-serif;
                    color: $secondary;
                    font-size: 1.5em;
                }
            }
        }
    }


    &.small{
        .container{
            background: $gray-100;
            padding: $spacing;

            .top-line{
                font-family: 'Oswald', sans-serif;
                color: $secondary;
                font-size: 1.5em;
                font-weight: 200;
            }

            .bottom-line{
                font-family: 'Playfair Display', sans-serif;
                color: $gray-600;
                font-size: 2.5em;
                font-weight: 700;
                line-height: 1.2em;
            }
        }
    }

    &.pink{
        .container{
            background: $primary;
            padding: $spacing;

            .top-line{
                font-family: 'Oswald', sans-serif;
                color: $white;
                font-size: 1.5em;
                font-weight: 200;
            }

            .bottom-line{
                font-family: 'Playfair Display', sans-serif;
                color: $white;
                font-size: 2.5em;
                font-weight: 700;
                line-height: 1.2em;
            }
        }
    }
}



    

// MEDIA QUERYS *************************************************
@include media-breakpoint-up(xs) {
    .e-news{
        &.big{
            .container{
                .top-line{
                    font-size: 1.5em;
                }
        
                .bottom-line{
                    font-size: 2em;
                    line-height: 1em;
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    
}

@include media-breakpoint-up(md) {
    
}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {
    .e-news{
        &.big{
            .container{
                .top-line{
                    font-size: 1.5em;
                }
        
                .bottom-line{
                    font-size: 2.5em;
                    line-height: 1.2em;
                }
            }
        }
    }
}