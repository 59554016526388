/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */

#gallery{

    #gallery-modal {
        position: fixed;
        background: red;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10000;
        overflow: auto;
        background-color: rgba(0,0,0,0.7);

        a{
            color: $gray-100;
            text-decoration: none;

            &:hover{
                color: $primary;
            }
        }
    }
}


// MEDIA QUERYS *************************************************
@include media-breakpoint-up(xs) {

}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}