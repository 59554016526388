/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */

#main{
    // profile ***********************************************************
    &.profile{
        padding-top: 1em;
        padding-bottom: 3em;

        .avatar{
            margin-top: 2.5em;
            width:80%;
            border-radius: 50%;
            text-align: center;
        }

        .edit{
            font-family: 'Montserrat', sans-serif;
            font-size: 0.8em;
            font-weight: 400;
            color: $gray-400;
            text-align: center;

            a{
                color: $gray-400;
                text-decoration: none;

                &:hover{
                    color: $secondary;
                }
            }

            i{
                color: $secondary;
            }
        }


        .name{
            font-family: 'Oswald', sans-serif;
            font-size: 2.5em;
            font-weight: 400;
            color: $gray-900;
            margin-top: $spacing*0.5;
        }

        .edit-icon{
            text-align: right;

            a{
                text-decoration: none;
            }
        }

        .personal-info,
        .payment-info,
        .links{
            font-family: 'Montserrat', sans-serif;
            font-size: 1.2em;
            font-weight: 400;
            margin-bottom: $spacing;

            a{
                text-decoration: none;
                color: $gray-600;

                &:hover{
                    color: $primary;

                    i{
                        color: $primary;
                    }
                }
            }

            span{
                width: 100%;
                padding: $gutter*0.5;
                display: flex;

                i{
                    color: $secondary;
                }
                


                &.strong{
                    font-family: 'Oswald', sans-serif;
                    font-weight: 400;
                }
            }
        }
    }



    // interests ***********************************************************
    &.interests{
        padding-top: 10em;
        padding-bottom: 5em;

        // navigation - tabs
        .nav{
            .nav-item{
                padding: 0;
                padding-right: 5em;

                .top-line{
                    display: block;
                    width: 18px;
                    height: 2px;
                    background: $gray-400;
                    margin-bottom: 5px;
                }
                .bottom-line{
                    display: block;
                    width: 12px;
                    height: 2px;
                    background: $gray-400;
                }

                .nav-link{
                    min-height: 5em;
                    padding:0;
                    font-family: 'Oswald', sans-serif;
                    font-size: 1.5em;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: $gray-500;
                    box-sizing: content-box;


                    &.active{
                        border-bottom: 2px solid $primary;
                    }
                }
            }
        }

        // tabs content
        .tab-content{
            .tab-pane{

                // author big
                .author-big{
                    .avatar{
                        width: 90%;
                        border-radius: 50%;
                        text-align: center;
                        padding: 5px;
                        border: 1px solid $gray-300;
                    }
                    .author-name{
                        font-family: 'Oswald', sans-serif;
                        font-size: 1.2em;
                        font-weight: 400;
                        color: $gray-600;
                        width: 100%;
                        display: block;
                    }
                    .author-sub{
                        font-family: 'Playfair Display', sans-serif;
                        font-size: 1em;
                        font-weight: 300;
                        color: $gray-600;
                        width: 100%;
                        display: block;
                    }
                }

                // author small
                .author-small{
                    padding-bottom: $spacing;
                    .avatar{
                        width: 80%;
                        border-radius: 50%;
                        text-align: center;
                        padding: 5px;
                        border: 1px solid $gray-300;
                    }
                    .author-name{
                        font-family: 'Oswald', sans-serif;
                        font-size: 1.5em;
                        font-weight: 400;
                        color: $gray-600;
                        text-align: center;
                        width: 100%;
                        display: block;
                        margin-top: $spacing*0.5;
                    }
                    .author-sub{
                        font-family: 'Playfair Display', sans-serif;
                        font-size: 1.2em;
                        font-weight: 300;
                        color: $gray-600;
                        text-align: center;
                        width: 100%;
                        display: block;
                        margin-bottom: $spacing*0.5;
                    }
                }

                // chosen for you
                .chosen-wrapper{
                    .title{
                        font-family: 'Oswald', sans-serif;
                        font-size: 1.2em;
                        font-weight: 400;
                        color: $black;
                    }
                    .chosen{
                        .title{
                            color: $gray-600;
                            font-family: 'Montserrat', sans-serif;
                            font-size: 1.2em;
                            font-weight: 400
                        }
                    }
                }


                // categories
                .category-wrapper{
                    .category{
                        .category-title{
                            display: block;
                            font-family: 'Oswald', sans-serif;
                            font-size: 1.2em;
                            color: $gray-600;
                            border-bottom: 1px solid $gray-200;
                            width: 20%;
                            min-width: 70px;
                            padding-bottom: 0.5em;
                            margin-bottom: 1em;
                            margin-left: 3px;
                            text-transform: uppercase;
                        }
                    }
                }


                // more authors
                .more-authors-wrapper{
                    .title{
                        display: inline-block;
                        font-family: 'Oswald', sans-serif;
                        font-size: 1.2em;
                        font-weight: 400;
                        color: $gray-600;
                        border-bottom: 1px solid $gray-200;
                        padding-bottom: 0.5em;
                    }

                    .more{
                        a{
                            font-family: 'Oswald', sans-serif;
                            font-size: 1.2em;
                            font-weight: 400;
                            color: $secondary;
                        }
                    }
                }

                // buttons
                button.following{
                    border: 1px solid $gray-200;
                    background: $gray-200;
                    font-family: 'Oswald', sans-serif;
                    font-size: 1em !important;
                    line-height: 2.5em;
                    color: $gray-400;
                    border-radius: 3px;
                    width: 7em;

                    span{
                        width: 100%;
                    }
                }
                button.follow{
                    border: 1px solid $secondary;
                    background: none;
                    font-family: 'Oswald', sans-serif;
                    font-size: 1em !important;
                    line-height: 2.5em;
                    color: $secondary;
                    border-radius: 3px;
                    width: 7em;

                    &:hover{
                        background: $gray-100;;
                    }
                }

            }
        }
        

        // ARTICLE - block-5 *******************************************
        .block-5{
            cursor: pointer;
            background: $white;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: $gutter*0.5;
        
            .img-wrapper{
                min-height: 35px;
                background: $gray-600;

                .img{
                    display: block;
                    width: 100%;
                }

                &::after {
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    content: ' ';
    
                    background: -moz-linear-gradient(top, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 31%, rgba(0,0,0,0) 100%);
                    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.4)), color-stop(31%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0)));
                    background: -webkit-linear-gradient(top, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 31%, rgba(0,0,0,0) 100%);
                    background: -o-linear-gradient(top, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 31%, rgba(0,0,0,0) 100%);
                    background: -ms-linear-gradient(top, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 31%, rgba(0,0,0,0) 100%);
                    background: linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 31%, rgba(0,0,0,0) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
                }
            }

            .overlay-top{
                position: absolute;
                top: 0;
                width: 100%;


                .follow{
                    position: absolute;
                    right: $gutter*0.5;
                    font-size: 1.8em;

                    .ico-check{
                        color: $gray-300;
                    }
                    .ico-plus{
                        color: $secondary;
                    }
                }
            }

            .overlay-bottom{
                position: absolute;
                bottom: 0.5em;
                width: 80%;
        
                .title{
                    padding: 0.2em 3% 0.2em 5%;
                    font-family: 'Oswald', sans-serif;
                    font-size: 2em;
                    line-height: 1.2em;
                    font-weight: 400;
                    color: $white;
                    text-transform: uppercase;
                }
            }

            &.big{
                .title{
                    font-size: 6em !important;
                }
            }
        }
    }


    // bookmarks ***********************************************************
    &.bookmarks{
        padding-top: 4em;
        padding-bottom: 0em;

        .bookmark{
            .ico-bookmark{
                color: $white;
            }
        }

    }

    // my tickets ***********************************************************
    &.my-tickets{
        padding-top: 4em;
        padding-bottom: 0em;
        
        .ticket{
            .overlay-top{
                position: absolute;
                width: 100%;

                .icon{
                    position: absolute;
                    top: $gutter;
                    right: $gutter*3;
                }
            }
            .overlay-bottom{
                .title{
                    min-height: 70px;
                }
            }
            .download{
                text-align: right;
                padding-top: $gutter*0.5;
                a{
                    color: $secondary;

                    &:hover{
                        text-decoration: none;
                        color: $gray-600;
                    }
                }
            }
        }

        .downloaded-tickets,
        .recently-bought{
            font-family: 'Montserrat', sans-serif;
            font-size: 1.2em;
            font-weight: 400;
            margin-bottom: $spacing;

            a{
                text-decoration: none;
                color: $gray-600;

                &:hover{
                    color: $primary;

                    i{
                        color: $primary;
                    }
                }
            }

            span{
                width: 100%;
                padding: $gutter*0.5;
                display: flex;

                i{
                    color: $secondary;
                }
                


                &.strong{
                    font-family: 'Oswald', sans-serif;
                    font-weight: 400;
                }
            }
        }
    }

    // my accounts ***********************************************************
    &.my-accounts{
        padding-top: 10em;
        padding-bottom: 0em;

    }
    
    // add user ***********************************************************
    &.add-user{
        padding-top: 10em;
        padding-bottom: 0em;

    }

    // register ***********************************************************
    &.register{
        padding-top: 10em;
        padding-bottom: 0em;
    }
}

// ARTICLE HOVER EFFECT
.block-5{
    cursor: pointer;
    .img-wrapper{
        overflow: hidden;
        .img{
            transition: all $speed cubic-bezier(0,0.3,.5,1) !important;
        }
    }
    &:hover{
        .img-wrapper .img{
            transform: scale(1.1) !important;
            opacity: 0.7 !important;
        }
    }
}






// UPLOAD MODAL **********************************************
#uploadModal{
    .modal-header,
    .modal-footer{
        min-height: 3.5em;
        background-image: url("../assets/img/content-bg.png");
        background-image: url("../assets/img/content-bg.png");
    }

    .modal-body{
        text-align:center;
        i{
            color: $gray-300;
        }
        span{
            font-family: 'Oswald', sans-serif;
            font-size: 0.8em;
            font-weight: 200;
            color: $gray-300;
            text-transform: uppercase;
        }

        .instructions{
            font-family: 'Oswald', sans-serif;
            font-size: 1em;
            font-weight: 200;
            color: $gray-500;
            text-transform: uppercase;
        }
    }

}


    

// MEDIA QUERYS *************************************************
@include media-breakpoint-up(xs) {
    #main{
        &.profile{    
            .name{
                text-align: center;
            }
        }
        &.interests{    
            .nav{
                .nav-item{  
                    padding-left: 1em; 
                    padding-right: 1em; 

                    .nav-link{
                        min-height: 3em;
                        display: block;
                        font-size: 1em;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    #main{
        &.profile{    
            .name{
                text-align: left;
            }
        }
        &.interests{    
            .nav{
                .nav-item{ 
                    padding:0;
                    padding-right: 5em;

                    .nav-link{
                        min-height: 5em;
                        display: flex;
                        font-size: 1em;
                        text-align: left;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    
}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}