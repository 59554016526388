/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */

#partner-menu{
    .partner-logo{
        margin-top: -125px;
        padding-left: 5%;

        .img{
            width: 180px;
        }
    }

    .social,
    .partner-nav{
        width: 100%;

        .nav-link{
            font-family: 'Oswald', sans-serif;
            font-size: 1.2em;
            color: $gray-600;
            font-weight: 400 !important;
            padding: 0.6em;

            &:hover{
                color: $gray-400;
            }
        }
    }
}

#about-us{
    font-family: 'Oswald', sans-serif;
    font-size: 1em;
    color: $gray-400;
    font-weight: 200;
    padding: 2em 3em !important;
}


// MEDIA QUERYS *************************************************
@include media-breakpoint-up(xs) {

}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}