/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */

 #body{
    #sidebar{
        // padding-left: $spacing;
        background: $gray-200;

        .block{
            // HEADINGS
            .heading{
                position:relative;
                display: block;
                font-family: 'Oswald', sans-serif;
                font-size: 2em;
                font-weight: 200;
                padding: 0.3em 0.6em 0.3em 20px;
                margin: 0px;
                text-transform: uppercase;

                background-color: $secondary;
                color: $white;
            }

            // CATEGORIES
            ul.categories{
                padding: 0px;
                li{
                    list-style: none;
                    font-family: 'Oswald', sans-serif;
                    font-size: 1em;
                    line-height: 3em;

                    a{
                        color: $gray-600;
                    }
                }
            }


            // SIDEBAR ARTICLE - block-4 *******************************************
            .block-4{
                cursor: pointer;
                background: $gray-600;
            
                .img-wrapper{
                    min-height: 35px;
                    background: $gray-600;

                    .img{
                        display: block;
                        width: 100%;
                    }
                }

                .bookmark{
                    position: absolute;
                    top: 0;
                    right: 10%;
                    color: $primary;
                }

                .overlay-top{
                    position: absolute;
                    top:0;
                    width: 60%;

                    img{
                        width: 30%;
                        min-width: 80px;
                    }
                    
                    .tag {
                        position: absolute;
                        top: 0;
                        left: 5%;
                        padding: 5px 10px;
                        font-family: 'Oswald', sans-serif;
                        font-size: 2em;
                        text-transform: uppercase;

                        &.gray{
                            background: $gray-600;
                            color: $white;
                            left: 0!important;
                            padding-left: 5%;
                        }

                        &.green{
                            background: $secondary;
                            color: $white;

                            &:after {
                                position: absolute;
                                left: $gutter * 2;
                                bottom: 0;
                                transform: translateY(100%);
                                display: block;
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-width: 13px 25px 0 0;
                                border-color: $secondary transparent transparent transparent;
                                content: "";
                            }
                        }
                    }
                }


                .overlay-bottom{
                    position: relative;
                    top: -1.8em;
                    width: 80%;

                    .author{
                        font-family: 'Playfair Display', serif;
                        font-size: 0.8em;
                        font-weight: 400;
                        color: $white;
                        padding: 0.5em 3% 0.5em 5%;
                    }

                    .clap-counter{
                        padding: 0.2em 3% 0.2em 5%;
                        font-family: 'Oswald', sans-serif;
                        font-size: 1em;
                        color: $white;
                    }
            
                    .category{
                        padding: 0.2em 3% 0.5em 5%;
                        font-family: 'Oswald', sans-serif;
                        font-size: 1em;
                        font-weight: 400;
                        color: $white;
                        text-transform: uppercase;
                        margin-bottom: -1px;
                        background-color: $gray-600;
                    }
            
                    .title{
                        padding: 0.2em 3% 0.2em 5%;
                        font-family: 'Oswald', sans-serif;
                        font-size: 2em;
                        line-height: 1.2em;
                        font-weight: 400;
                        color: $white;
                        text-transform: uppercase;
                    }
                }

                &.big{
                    .title{
                        font-size: 6em !important;
                    }
                }
            }
        }
    }
}


// ARTICLE HOVER EFFECT
.block-4{
    cursor: pointer;
    .img-wrapper{
        overflow: hidden;
        .img{
            transition: all $speed cubic-bezier(0,0.3,.5,1) !important;
        }
    }
    &:hover{
        .img-wrapper .img{
            transform: scale(1.1) !important;
            opacity: 0.7 !important;
        }
    }
}

// MEDIA QUERYS *************************************************
@include media-breakpoint-up(xs) {
    #body{
        #sidebar{
            padding-left: $spacing*0.5;
            .block{
                padding-left: 0;
            }
        }
    }

    #body{
        #sidebar{
            .block{
                .block-4{
                    .overlay-top{                    
                        .tag {
                            font-size: 1.5em;
                        }
                    }
                    .overlay-bottom{
                        width: 95%;
                        top: -1.5em;

                        .clap-counter,
                        .category,
                        .author{
                            font-size: 0.8em;
                        }

                        .title{
                            font-size: 1.3em;
                        }
                    }
                }
            }
        }
    }

}

@include media-breakpoint-up(sm) {
    #body{
        #sidebar{
            .block{
                .block-4{
                    .overlay-top{                    
                        .tag {
                            font-size: 2em;
                        }
                    }
                    .overlay-bottom{
                        width: 95%;
                        top: -1.9em;

                        .clap-counter,
                        .category,
                        .author{
                            font-size: 1em;
                        }

                        .title{
                            font-size: 2em;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    #body{
        #sidebar{
            padding-left: $spacing*0.5;
            // .block{
            //     // padding-left: $spacing*0.5;
            // }
        }
    }

    #body{
        #sidebar{
            .block{
                .block-4{
                    .overlay-top{                    
                        .tag {
                            font-size: 1.2em;
                        }
                    }
                    .overlay-bottom{
                        width: 90%;
                        top: -1.2em;

                        .clap-counter,
                        .category,
                        .author{
                            font-size: 0.8em;
                        }

                        .title{
                            font-size: 1.2em;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    #body{
        #sidebar{
            .block{
                .block-4{
                    .overlay-top{                    
                        .tag {
                            font-size: 1.5em;
                        }
                    }
                    .overlay-bottom{
                        width: 95%;
                        top: -1.5em;

                        .clap-counter,
                        .category,
                        .author{
                            font-size: 0.8em;
                        }

                        .title{
                            font-size: 1.5em;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    #body{
        #sidebar{
            .block{
                .block-4{
                    .overlay-top{                    
                        .tag {
                            font-size: 2em;
                        }
                    }
                    .overlay-bottom{
                        width: 95%;
                        top: -1.8em;

                        .clap-counter,
                        .category,
                        .author{
                            font-size: 1em;
                        }

                        .title{
                            font-size: 2em;
                        }
                    }
                }
            }
        }
    }
}