/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */

#breadcrumbs{
    .breadcrumb{
        background-color: transparent;
        font-family: 'Playfair Display', sans-serif;
        color: $gray-600;
        padding-top: $spacing*0.8;
        padding-bottom: $spacing*0.8;
        margin-bottom: 0;
    }
}

// MEDIA QUERYS *************************************************
@include media-breakpoint-up(xs) {

}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}