/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */

/*
Override bootstraps variables
*/
$primary:       #80217e;
$secondary:     #95c11f;
// $success:       $green;
// $info:          $cyan;
// $warning:       $yellow;
// $danger:        $red;
// $light:         $gray-100;
// $dark:          $gray-800;


$white:    #fff;
$gray-100: #f6f6f6; // backgrounds 1
$gray-200: #e9e9e9; // backgrounds 2
$gray-300: #bebebe; // footer icons
$gray-400: #b9b9b9; // lite heading bars - rahlo siva, tudi footer
$gray-500: #767676; // footer text
$gray-600: #505050; // dark heading bars
$gray-700: #333333; // footer
$gray-800: #292929; // submenu
$gray-900: #171717; // menu
$black:    #000;
$ads-bg:   #f8a4d1; // adds background

// $primary:       $blue !default;
// $secondary:     $gray-600 !default;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
$light: $gray-400;
$dark: $gray-600;

$speed: 300ms;
$gutter: 15px;
$spacing: 30px;
$grid-gutter-width: 30px;

$shadow: 0px 0px 50px 0px rgba(0,0,0,0.15);