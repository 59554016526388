/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */

header{
    z-index: 500;
    /*****************************************
    * DESKTOP NAVIGATION
    *****************************************/
    &.desktop{
        .top-menu {
            background-color: $black;
    
            .container{
                .row{
                    min-height: 25px;
                }
            }
            .nav{
                &.social{
                    .nav-item{
                        .nav-link{
                            font-size: 1em;
                        }
                    }
                }
                .nav-item{
                    .nav-link{
                        font-family: 'Montserrat', sans-serif;
                        color: $gray-400;
                        font-size: 0.8em;
                        padding: 0 1em;
    
                        &:hover{
                            color: $primary;
                        }
                        &.active{
                            color: $primary;
                        }
                    }
                }
            }
        }
        
        .main-menu {
            background-color: $gray-900;
            position: relative;
            
            // SEARCH
            #search{
                visibility: hidden;
                position: absolute;
                background: $gray-900;
                z-index: 600;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                opacity: 0;

                transition: all $speed;

                input{
                    background: transparent;
                    width: 100%;
                    min-height: 50px;
                    border: none;
                    font-family: 'Oswald', sans-serif;
                    font-size: 1.2em;
                    font-weight: 200;               
                    color: $white;
                }

                .close{
                    font-size: 2em;
                    color: $white;
                    padding-right: 16px;
                }

                &.active{
                    opacity:1;
                    visibility: visible;
                }
                // &.inactive{
                //     opacity:0;
                //     display: none;
                // }
            }
    
            .container{
                .row{
                    min-height: 75px;
                }
            }
            .logo{
                padding-left: 12px;
                max-width: 210px;
            }
            .nav{
                &.main-nav-left{
                    .nav-item{
                        .nav-link{
                            font-size: 1.2em;
                            line-height: 4.2em;
                        }
                    }
                }
                &.main-nav-right{
                    .nav-item{
                        .nav-link{
                            font-size: 1em;
                        }
                    }
                }
                .nav-item{
                    .nav-link{
                        font-family: 'Oswald', sans-serif;;
                        color: $gray-400;
                        font-weight: 200;
    
                        &:hover{
                            color: $primary;
                        }
                        &.active{
                            color: $primary;
                        }
                    }
                }
            }


            // megamenu
            .mega-menu{
                &:hover{
                    .mega-menu-container{
                        visibility:visible;
                        opacity: 1;
                        min-height: 100%;
                    }
                }
            }

            .mega-menu-container {
                visibility:hidden;
                position:absolute;
                min-height: 0%;
                padding-top: $gutter*0.5;
                padding-bottom: $gutter*0.5;
                background-color: $gray-100;
                box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
                z-index: 10000;
                opacity: 0;
                transition: all $speed*0.5 linear;

                .menu{
                    background: $white;
                    padding-right: 0!important;

                    &:first-of-type{
                        border-right: 1px solid $gray-400;
                    }

                    .nav{
                        .nav-item{
                            width: 100%;
                            float:left;
                            padding: 0.1em 0 !important;

                            .nav-link{
                                font-family: 'Oswald', sans-serif;
                                color: $gray-500;
                                font-size: 1em;
                                line-height: 1em;
                                font-weight:400;
                                text-transform: uppercase;
                                white-space: nowrap;
                                
                                span{
                                    display: block;
                                    background: $primary;
                                    height: 1px;
                                    width: 0%;
                                    margin-left: $gutter;

                                    transition: width $speed;
                                }

                                &.active,
                                &:hover{
                                    color: $primary;

                                    span{
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }


            // profile dropdown

            .dropdown-menu{
                &:hover{
                    .dropdown-menu-container{
                        visibility:visible;
                        opacity: 1;
                        min-height: 100%;
                    }
                }
            }

            .dropdown-menu-container{
                visibility:hidden;
                position:absolute;
                right: 70px;
                min-height: 100%;
                max-width: 180px;
                background-color: $white;
                box-shadow: 0 0 20px 0px rgba(0,0,0,0.2), 0 10px 10px rgba(0,0,0,0.1);
                z-index: 10001;
                opacity: 0;
                transition: all $speed*0.5 linear;

                .nav{
                    .nav-item{
                        width: 100%;
                        float:left;
                        padding: 0.7em 1em !important;
                        border-bottom: 1px solid $gray-100;
                        transition: all 0.3s cubic-bezier(.25,.8,.25,1);

                        &.active,
                        &:hover{
                            box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.2);

                            .nav-link{
                                font-weight:400 !important;
                            }
                        }

                        .nav-link{
                            font-family: 'Oswald', sans-serif;
                            color: $gray-500;
                            font-size: 0.9em;
                            line-height: 1em;
                            font-weight:300;
                            text-transform: uppercase;
                            
                            &.logout{
                                color: $secondary;
                            }
                        }
                    }

                    &:before {
                        position: absolute;
                        right: 0;
                        top: -8px;
                        //transform: translateY(100%);
                        display: block;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0px 0px 9px 10px;
                        border-color: transparent transparent $white transparent;
                        content: "";
                    }
                }
            }
        }
        




        .bottom-menu {
            background-color: $gray-800;
    
            .container{
                .row{
                    min-height: 32px;
                }
            }
            .nav{
                .nav-item{
                    .nav-link{
                        font-family: 'Oswald', sans-serif;
                        color: $gray-400;
                        font-size: 1.2em;
                        font-weight:200;
                        padding: 0 1em;
    
                        &:hover{
                            color: $primary;
                        }
                        &.active{
                            color: $primary;
                        }
                    }
                }
            }
        }
    }



    /*****************************************
    * MOBILE NAVIGATION
    *****************************************/
    &.mobile{
        .main-menu {
            position: relative;
            background-color: $gray-900;
            z-index: 10 !important;
    
            .container{
                .row{
                    min-height: 60px;
                }
            }
            .logo{
                padding-left: 12px;
            }
            .nav{
                &.main-nav-left{
                    .nav-item{
                        .nav-link{
                            font-size: 1.2em;
                        }
                    }
                }
                &.main-nav-right{
                    .nav-item{
                        .nav-link{
                            font-size: 1em;
                        }
                    }
                }
                .nav-item{
                    .nav-link{
                        font-family: 'Oswald', sans-serif;;
                        color: $gray-400;
                        //font-size: 1.2em;
                        padding: 0.8em;
    
                        &:hover{
                            color: $primary;
                        }
                        &.active{
                            color: $primary;
                        }
                    }
                }
            }
        }

        .top-menu {
            position: relative;
            background-color: $gray-800;
            z-index: 5!important;
    
            .container{
                .row{
                    min-height: 36px;
                }
            }
            .nav{
                .nav-item{
                    .nav-link{
                        font-family: 'Montserrat', sans-serif;
                        color: $gray-400;
                        font-size: 1em;
                        padding: $gutter*0.5;
    
                        &:hover{
                            color: $primary;
                        }
                        &.active{
                            color: $primary;
                        }
                    }
                }
            }
        }


        // SEARCH
        #search{
            position: relative;
            background: $gray-800;
            z-index: 100 !important;
            height: 100%;
            border-top: 1px solid $gray-900;
            padding-left: $spacing;

            input{
                background: transparent;
                width: 100%;
                min-height: 50px;
                border: none;
                font-family: 'Oswald', sans-serif;
                font-size: 1.2em;
                font-weight: 200;               
                color: $white;
            }

            a{
                color: $gray-400;

                &:hover{
                    color: $primary;
                    text-decoration: none;
                }
            }
        }





        // MOBILE SLIDE OUT NAVIGATION
        .mobile-menu { 
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 0%;
            z-index: 0!important;
            background-color: $gray-900;
            overflow-x: hidden;
            opacity: 0;
            transition: $speed;

            ul {
                list-style-type: none;
            }

            &.active{
                width: 100%;
                height: calc(100% - 140px);
                top: 140px;
                opacity: 1;
            }

            .content {
                display: block;
                position: relative;
                top: 3em;
                width: 80%;
                margin:auto;
                padding:0;
                max-width: 450px;
                text-align: left;
                // font-size: 2em;


                .level-1 a,
                .level-2 a,
                .level-3 a{
                    padding: 0.3em;
                    font-family: 'Oswald', sans-serif;
                    text-decoration: none;
                    color: $gray-400;
                    display: block;
                    transition: $speed;
                }

                .level-1 a {
                    font-size: 1.7em;

                    &:hover{
                        color: $primary;
                    }
                    &.active{
                        color: $primary;
                    }
                }

                .level-2 a {
                    font-size: 1.6em;

                    &:hover{
                        color: $primary;
                    }
                    &.active{
                        color: $primary;
                    }
                }

                .level-3 a {
                    padding: 0.3em;

                    text-decoration: none;
                    font-size: 1.5em;
                    color: $gray-400;
                    display: block;
                    transition: $speed;
        
                    &:hover{
                        color: $primary;
                    }
                    &.active{
                        color: $primary;
                    }
                }

                .menu-divider{
                    display: block;
                    height: 1px;
                    width: 100%;
                    background-color: $gray-400;
                }

                form{
                    color: $gray-400;
                    width: 100%;

                    input{
                        width: 60%;
                        height: $spacer*5;
                        background: none;
                        border: 0;
                        border-radius: 0;
                        border-bottom: 2px solid $gray-400;
                        font-family: 'Oswald', sans-serif;
                        color: $gray-600;
                        font-size: 2em;
                        font-weight: 200;

                        &:focus{
                            box-shadow: 0 0 3px 1px rgba($gray-400, 0.5);
                        }

                        &::placeholder{
                            font-family: 'Oswald', sans-serif;
                            color: $gray-400;
                            font-size: 0.5em;
                            font-weight: 400;
                            line-height: 2em;
                            text-transform: uppercase;
                            padding-left: 0.5em;
                        }
                    }

                    .menu-divider{
                        display: block;
                        height: 1px;
                        width: 100%;
                        background-color: $gray-400;
                    }
                }

            }
        }
        
        // icon
        $space: 8;
        #menu-icon {
            width: 20px;
            height: 20px;
            position: relative;
            margin: 0px auto;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: $speed ease-in-out;
            -moz-transition: $speed ease-in-out;
            -o-transition: $speed ease-in-out;
            transition: $speed ease-in-out;
            cursor: pointer;
        }
        
        #menu-icon span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background: $gray-400;
            border-radius: 1px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: $speed ease-in-out;
            -moz-transition: $speed ease-in-out;
            -o-transition: $speed ease-in-out;
            transition: $speed ease-in-out;
        }
        
        #menu-icon span:nth-child(1) {
            top: 0px;
        }
        
        #menu-icon span:nth-child(2),
        #menu-icon span:nth-child(3) {
            top: $space*1px;
        }
        
        #menu-icon span:nth-child(4) {
            top: $space*2px;
        }
        
        #menu-icon.open span:nth-child(1) {
            top: $space*1px;
            width: 0%;
            left: 50%;
        }
        
        #menu-icon.open span:nth-child(2) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        
        #menu-icon.open span:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
        
        #menu-icon.open span:nth-child(4) {
            top: $space*1px;
            width: 0%;
            left: 50%;
        }
          

    }
}



// MEDIA QUERYS *************************************************
@include media-breakpoint-up(xs) {

}

@include media-breakpoint-up(sm) {
    
}

@include media-breakpoint-up(md) {
    header{
        &.desktop{
            .main-menu {
                // megamenu menu
                .mega-menu-container {
                    left: 0px;
                    right: -301px;
                    top: 73px;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    header{
        &.desktop{
            .main-menu {
                // megamenu menu
                .mega-menu-container {
                    left: -240px;
                    right: -320px;
                    top: 73px;

                    .menu{              
                        .nav{
                            .nav-item{
                                padding: 0.2em 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    header{
        &.desktop{
            .main-menu {
                // megamenu menu
                .mega-menu-container {
                    left: -285px;
                    right: -380px;
                    top: 73px;

                    .menu{              
                        .nav{
                            .nav-item{
                                padding: 0.3em 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}