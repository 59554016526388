.slider {
    width: 100% !important;
    height: 2px;
    background: $gray-300;
    position: relative;

    &.one-way .handle.handle-left {
        visibility: hidden;
    }

    .slider-circle {
        width: 30px;
        height: 30px;
        position: relative;
        margin-left: -100%;
        display: inline-block;
        margin-top: 1rem;
        pointer-events: none;

        font-family: 'Oswald', sans-serif;
        font-size: 1.2em;
        font-weight: 300;
        color: $gray-600;
    }

    .handle {
        width: 1em;
        height: 1em;
        border-radius: 50%;
        position: absolute;
        top: -0.4em;
        border: 2px solid $secondary;
        background-color: #fff;
        text-align: center;
        cursor: pointer;
        z-index: 1;
        -webkit-tap-highlight-color: transparent;

        &.ontop {
            z-index: 2;
        }

        &:active {
            border-color: $gray-400;
        }
    }

    .handle-left {
        left: 0;
    }

    .handle-right {
        right: 0;
    }

    .slider-fill {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $secondary;
        pointer-events: none;
        -webkit-user-select: none;
                user-select: none;
    }

    .slider-transition {
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
}

// MEDIA QUERYS *************************************************
@include media-breakpoint-up(xs) {

    .slider {
        .handle {
            width: 2.5em;
            height: 2.5em;

            top: -1.2em;
        }
    }

}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {
    .slider {
        .handle {
            width: 1em;
            height: 1em;

            top: -0.4em;
        }
    }
}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}
