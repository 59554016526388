/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */

// imports
@import "bootstrap";
@import "icons";
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700|Oswald:200,400,600|Playfair+Display:400,700&subset=latin-ext');

// our imports
@import "general";
@import "variables";

// content
@import "header";
@import "footer";
@import "sidebar";
@import "profile";
@import "gallery";
@import "prize-game";
@import "work";
@import "food";
@import "partner";
@import "edu";
@import "menu";
@import "sliders";
@import "breadcrumbs";
@import "carousel";
@import "content";
@import "forms";
@import "article-blocks";
@import "e-news";
@import "ads";
@import "modal";