/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */

.promo{
    .promo-wrapper{
        padding-bottom: $spacer!important;
        width: 100%;
        height: 100%;
        min-height: 100px;
        // background: $ads-bg;

        .img-fluid{
            width: 100%;
            height: auto !important;
        }
    }
}


// MEDIA QUERYS *************************************************
@include media-breakpoint-up(xs) {

}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}