/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */

.modal{
    &.wide{
        .modal-dialog{
            max-width: 728px !important;
        }
    }
    .modal-content{
        background: $white;

        .modal-header{
            border-bottom: none;
        }

        .modal-body{
            padding:  0 $spacing*3;

            .top-line{
                font-family: 'Oswald', sans-serif;
                color: $secondary;
                font-size: 1.5em;
                font-weight: 200;
                text-align: center;
                padding-bottom: $spacing;
            }

            .bottom-line{
                font-family: 'Playfair Display', sans-serif;
                color: $gray-600;
                font-size: 2.5em;
                font-weight: 700;
                line-height: 1.2em;
                text-align: center;
                padding-bottom: $spacing;

                span{
                    font-family: 'Playfair Display', sans-serif;
                    color: $secondary;
                    font-size: 1.5em;
                }
            }

            form{
                // input{
                //     display:block;
                //     width: 100%;
                //     height: $spacer*5;
                //     background: none;
                //     border: 0;
                //     border-radius: 0;
                //     border-bottom: 2px solid $secondary;
                //     font-family: 'Oswald', sans-serif;
                //     color: $gray-600;
                //     font-size: 2em;
                //     font-weight: 200;

                //     &:focus{
                //         box-shadow: 0 0 3px 1px rgba($secondary, 0.5);
                //     }

                // }

                // button{
                //     display: block;
                //     width: 100%;
                //     padding: $spacing*0.5;
                //     background: none;
                //     border: 2px solid $secondary;
                //     border-radius: 5px;
                //     font-family: 'Oswald', sans-serif;
                //     color: $secondary;
                //     font-size: 1em;
                //     font-weight: 400;
                //     text-transform: uppercase;
                //     cursor: pointer;

                //     &:hover{
                //         background: rgba($secondary, 0.2);
                //     }
                // }
            }
        }

        .modal-footer{
            border-top: none;
        }
    }


    // modal for tickets #1
    &#ticket1,
    &#ticket2,
    &#ticket3,
    &#ticket4,
    &#ticket5{
        #ticket-picker{
            .nav-item{
                .nav-link{
                    font-family: 'Oswald', sans-serif;
                    color: $gray-600;
                    font-size: 1em;
                    font-weight: 400;
                    text-transform: uppercase;
                    border-bottom: 2px solid $gray-200;
                    text-align: center !important;
                    display:block;

                    &.active{
                        border-bottom: 2px solid $secondary;
                    }

                }
            }
        }

        .promo-code{
            font-family: 'Oswald', sans-serif;
            color: $gray-300;
            font-size: 1em;
            line-height: 3em;
            font-weight: 400;
        }

        .meta{
            background: $gray-200;
            padding: $spacing $spacing*1.5;
            margin-bottom: $gutter;

            .title{
                font-family: 'Oswald', sans-serif;
                color: $gray-600;
                font-size: 1.5em;
                font-weight: 400;
                text-transform: uppercase;
            }
            .ticket-type{
                font-family: 'Oswald', sans-serif;
                color: $gray-600;
                font-size: 1.2em;
                font-weight: 400;
                text-transform: uppercase;
            }
        }

        .ticket-select{
            padding-left: $spacing*1.5;
            padding-right: $spacing*1.5;
            margin-bottom: $gutter;

            .title{
                font-family: 'Oswald', sans-serif;
                color: $gray-600;
                font-size: 1.2em;
                font-weight: 400;
                text-transform: uppercase;
            }
            .price{
                font-family: 'Oswald', sans-serif;
                color: $gray-600;
                font-size: 1.2em;
                font-weight: 400;
                text-transform: uppercase;
                text-align: center;
            }

            .select{
                padding: 0;
                margin: 0;
                border: 1px solid $secondary;
                border-radius: 3px;
                overflow: hidden;
                height: 40px; 
                font-family: 'Oswald', sans-serif;
                color: $gray-600;
                font-size: 1.2em;
                font-weight: 400;
                outline: none !important;

                select{
                    position: absolute;
                    height: 40px;             
                    font-size: 1em;
                    color: $gray-500;
                    font-weight: 300;
                    outline: none !important;
                    width: 120%;
                    border: none !important;
                    box-shadow: none !important;
                    background-color: transparent !important;
                    background-image: none !important;
                    z-index: 20;
                    -webkit-appearance: none !important;
                    -moz-appearance: none;
                    appearance: none;
                    padding-left: 1em;

                    color: transparent;
                    text-shadow: 0 0 0 #000;

                    &:focus {
                        outline: none;
                    }
                }

                &:before {
                    cursor: pointer !important;
                    position: absolute;
                    font-family: mladismo;
                    color: $secondary;
                    content: "\e924";
                    top: 10px;
                    right: 10%;
                    height: 40px; 
                    z-index: 1;
                }
            }
        }

        .price-sum{
            padding-left: $spacing*1.5;
            padding-right: $spacing*1.5;
            padding-top: $gutter;
            border-top: 2px solid $gray-200;
            .title{
                font-family: 'Oswald', sans-serif;
                color: $gray-600;
                font-size: 1.2em;
                font-weight: 400;
                text-transform: uppercase;
            }
            .price{
                font-family: 'Oswald', sans-serif;
                color: $gray-600;
                font-size: 2em;
                font-weight: 400;
                text-transform: uppercase;
                text-align: center;
            }
        }

        .overview{
            background: $gray-200;
            
            .title{
                font-family: 'Oswald', sans-serif;
                color: $gray-600;
                font-size: 1.2em;
                font-weight: 300;
                text-transform: uppercase;

            }
            .price{
                font-family: 'Oswald', sans-serif;
                color: $gray-600;
                font-size: 2em;
                line-height: 2em;
                font-weight: 300;
                text-transform: uppercase;
                margin-left: $gutter;
            }

            .button-buy{
                background: $secondary; 
                min-height: 60px;
                padding-left: $gutter*3;

                font-family: 'Oswald', sans-serif;
                color: $white;
                font-size: 1em;
                font-weight: 400;
                text-transform: uppercase;

                i{  
                    // position: absolute;
                    max-width: 50px;
                    margin-left: 50%;
                    right: $gutter*2.5;
                }
            }
        }

        .login{
            .title{
                font-family: 'Oswald', sans-serif;
                color: $gray-600;
                font-size: 1.5em;
                font-weight: 400;
            }
            .instructions{
                font-family: 'Montserrat', sans-serif;
                color: $gray-300;
                font-size: 1em;
                font-weight: 300;
            }
        }

        .register{
            .title{
                font-family: 'Oswald', sans-serif;
                color: $white;
                font-size: 1.5em;
                font-weight: 400;
                text-transform: uppercase;
            }
            .link{
                a{
                    font-family: 'Oswald', sans-serif;
                    color: $secondary;
                    font-size: 1.2em;
                    font-weight: 400;
                    text-transform: uppercase;
                }
            }
        }
        .why-register{
            .title{
                font-family: 'Oswald', sans-serif;
                color: $white;
                font-size: 1em;
                font-weight: 300;
            }
            .list{
                font-family: 'Montserrat', sans-serif;
                color: $gray-300;
                font-size: 1em;
                font-weight: 300;

                ul{
                    padding-left: $gutter;
                }
            }
        }

        .ticket{
            background: $gray-100;

            .title{
                font-family: 'Oswald', sans-serif;
                color: $gray-600;
                font-size: 1.5em;
                font-weight: 400;
                text-transform: uppercase;
            }
            .ticket-price{
                font-family: 'Oswald', sans-serif;
                color: $gray-600;
                font-size: 2.5em;
                font-weight: 400;
                text-transform: uppercase;
            }
            .remove{
                position: absolute;
                top: $gutter;
                right: $gutter;
                font-size: 2em;

                a{
                    color: $gray-600;
                    text-decoration: none;
                }
            }
        }

        .payment-method{
            .instructions{
                font-family: 'Oswald', sans-serif;
                color: #505050;
                font-size: 1.5em;
                font-weight: 400;
            }
            // RADIOS
            // .form-check{
            //     .form-check-label{
            //         font-family: 'Oswald', sans-serif;
            //         color: $gray-500;
            //         font-size: 1.2em;
            //         font-weight: 200;
            //     }
            // }

            input[type=radio] { display:none !important; }
            input[type=radio] + label:before {
                font-family: mladismo;
                display: block;
                text-align: center;
                clear: both;
                content: "\e920";
                letter-spacing: 0px;
            }

            input[type=radio]:checked + label:before {
                content: "\e91f";
            }
        }



        // PROGRESSBAR
        .progressbar {
            padding: 0 !important;
            width: 100%;
            li {
                font-family: 'Montserrat', sans-serif;
                color: $gray-600;
                font-size: 1em;
                font-weight: 300;

                list-style-type: none;
                width: 25%;
                float: left;
                position: relative;
                text-align: center;
                color: $gray-500;
            
                &:before {
                    width: 6px;
                    height: 6px;
                    content: '';
                    border: 5px solid $gray-500;
                    display: block;
                    text-align: center;
                    margin: 0 auto $gutter auto;
                    border-radius: 50%;
                    background-color: white;
                }
                &:after {
                    width: 100%;
                    height: 2px;
                    content: '';
                    position: absolute;
                    background-color: #7d7d7d;
                    top: 4px;
                    left: -50%;
                    z-index: -1;
                }
                &:first-child:after {
                    content: none;
                }
                &.active {
                    color: $secondary;
                }
                &.active:before {
                    border-color: $secondary;
                }
                &.active + li:after {
                    background-color: $secondary;
                }
            }
        }
    }
}



// MEDIA QUERYS *************************************************
@include media-breakpoint-up(xs) {
    .modal{
        font-size: 0.8em;
        .modal-content{    
            .modal-body{
                padding:  0 $spacing;
            }
        }

        &#ticket1,
        &#ticket2,
        &#ticket3,
        &#ticket4,
        &#ticket5{
            .ticket-select{
                padding-left: $spacing*0.3;
                padding-right: $spacing*0.3;
            }
            .price-sum{
                padding-left: $spacing*0.3;
                padding-right: $spacing*0.3;
            }
            .meta{
                padding: $spacing*0.5 $spacing*0.5;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .modal{
        font-size: 1em;

        &.wide{
            .modal-dialog{
                width: 90% !important;
            }
        }
        .modal-content{    
            .modal-body{
                padding:  0 $spacing;
            }
        }

        &#ticket1,
        &#ticket2,
        &#ticket3,
        &#ticket4,
        &#ticket5{
            .ticket-select{
                padding-left: $spacing*1.5;
                padding-right: $spacing*1.5;
            }
            .price-sum{
                padding-left: $spacing*1.5;
                padding-right: $spacing*1.5;
            }
            .meta{
                padding: $spacing $spacing*1.5;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .modal{
        .modal-content{    
            .modal-body{
                padding:  0 $spacing;
            }
        }
    }
}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}