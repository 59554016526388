/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */


// prize game block *******************************************
.prize-block{
    .img-wrapper{
        display:block;
        padding:0;
        margin-left: -$gutter;
        margin-right: -$gutter;

        img{
            display: block;
            width: 100%;
        }
        
        &::after {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            content: ' ';

            background: -moz-linear-gradient(top, rgba(0,0,0,0) 20%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.6) 100%);
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 20%,rgba(0,0,0,0.1) 50%,rgba(0,0,0,0.6) 100%);
            background: linear-gradient(to bottom, rgba(0,0,0,0) 20%,rgba(0,0,0,0.1) 50%,rgba(0,0,0,0.6) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#66000000',GradientType=0 );
        }
    }
    .wrapper{
        .overlay-top{
            position: absolute;
            top:0;
            left:0;
            width: 100%;
        }

        .overlay-bottom{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 90%;
    
            .title{
                padding: 0.05em 3% 0.05em 2%;
                font-family: 'Oswald', sans-serif;
                line-height: 1.2em;
                font-weight: 400;
                color: $white;
                text-transform: uppercase;
            }
            .excerpt{
                padding: 0.05em 3% 0.05em 2%;
                font-family: 'Oswald', sans-serif;
                line-height: 1.2em;
                font-weight: 400;
                color: $white;
            }

            .tag {
                margin-left: 2%;
                margin-top: 2em;
                padding: 0.3em 0.8em;
                font-family: 'Oswald', sans-serif;
                text-transform: uppercase;

                background: $secondary;
                color: $white;
                font-weight: 400;
            }
        }
    }
}

.container{
    .prize-game-steps{
        h3{
            line-height: 1.5em;
            font-family: 'Oswald', sans-serif;
        }
        .step{
            span{
                display: block;
                min-width: 100%;
                line-height: 1.5em;
                font-family: 'Oswald', sans-serif;
                font-size: 1.2em;
                padding-bottom: 0.5em;
            }
            textarea{
                width: 100%;
                max-width: 400px;
                padding: 1em;
                border: 1px solid $secondary !important;
                border-radius: 3px;
                color: $gray-500;
                resize: none;
                font-family: 'Montserrat', sans-serif;

                &::placeholder{
                    color: $gray-300 !important;
                }
                &:focus{
                    box-shadow: none;
                }
            }
            button{
                font-family: 'Oswald', sans-serif;
                font-size: 16px !important;
                font-weight: 400;

                color: $secondary;
                background: $white;
                border: 1px solid $secondary;
                border-radius: 3px;
                padding: 0.5em 1.5em;
                text-transform: uppercase;
                
                &:hover{
                    background: $gray-100;
                }

                &.shadow{
                    box-shadow: $shadow;
                }

                // share button
                &#share{
                    background:rgb(59, 89, 152);
                    border: none;
                    color: $white;

                    i{
                        margin-left: -$gutter*0.5;
                    }

                    .text{
                        display: inline-block;
                        padding-left: $gutter;
                    }
                }
            }
        }
    }

    .prize-game-details{
        border-top: 1px solid $gray-400;
        border-bottom: 1px solid $gray-400;


        .participants{
            line-height: 1.5em;
            font-family: 'Oswald', sans-serif;
            font-size: 1.2em;
            padding-bottom: 0.5em;
        }
        .time{
            text-align: right;

            line-height: 1.8em;
            font-family: 'Oswald', sans-serif;
            font-size: 1.2em;
        }
    }


    .prize-game-group > .row {
        overflow-x: auto;
        white-space: nowrap;
        overflow: -moz-scrollbars-none;

        &::-webkit-scrollbar {
            display: none;
        }
    }
    .prize-game-group > .row > article {
        display: inline-block;
        float: none;
    }


    #aktualSlider{
        width: 90%;
        padding-top: $gutter*2;
        margin:auto;
        padding-bottom: $gutter;
    }
}




// MEDIA QUERYS *************************************************
@include media-breakpoint-down(xs) {
    // prize game block
    .prize-block{
        .wrapper{   
            .overlay-top{
                i{
                    font-size: 1.2em;
                }
            } 
        }
    }
}
@include media-breakpoint-up(xs) {
    // prize game block
    .prize-block{
        .wrapper{   
            .overlay-bottom{
                width: 95%;
        
                .title{
                    font-size: 1em;
                }
                .excerpt{
                    padding: 0.05em 3% 0.05em 2%;
                    font-size: 0.8em;
                }
                .tag {
                    margin-top: 0.8em;
                    font-size: 0.8em;
                    padding: 0.3em 0.8em;
                }
            }
        }
    }
}


@include media-breakpoint-up(sm) {
    // prize game block
    .prize-block{
        .wrapper{
            .overlay-bottom{
                width: 80%;
        
                .title{
                    font-size: 1.4em;
                }
                .excerpt{
                    padding: 0.05em 3% 0.05em 2%;
                    font-size: 1em;
                }
                .tag {
                    margin-top: 1em;
                    font-size: 1em;
                    padding: 0.3em 0.8em;
                }
            }
        }
    }
}


@include media-breakpoint-up(md) {
    // prize game block
    .prize-block{
        .wrapper{
            .overlay-bottom{
                width: 70%;
        
                .title{
                    font-size: 1.2em;
                }
                .excerpt{
                    padding: 0.05em 3% 0.05em 2%;
                    font-size: 1em;
                }
                .tag {
                    margin-top: 1.5em;
                    font-size: 1em;
                    padding: 0.3em 0.8em;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    // prize game block
    .prize-block{
        .wrapper{    
            .overlay-bottom{
                width: 60%;

                .title{
                    font-size: 1.5em;
                }
                .excerpt{
                    padding: 0.05em 3% 0.05em 2%;
                    font-size: 1.2em;
                }
                .tag {
                    margin-top: 2em;
                    font-size: 1.2em;
                    padding: 0.3em 0.8em;
                }
            }
        }
    } 
}

@include media-breakpoint-up(xl) {
    // prize game block
    .prize-block{
        .wrapper{
            .overlay-bottom{
                width: 60%;

                .title{
                    font-size: 1.8em;
                }
                .excerpt{
                    padding: 0.05em 3% 0.05em 2%;
                    font-size: 1.2em;
                }
                .tag {
                    margin-top: 2em;
                    font-size: 1.2em;
                    padding: 0.3em 0.8em;
                }
            }
        }
    }
}