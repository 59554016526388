/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */

.edu{
    .main-image{
        .overlay-bottom{
            position: absolute;
            bottom: 0;
            background: $gray-600;

            .title{
                font-family: 'Oswald', sans-serif;
                font-size: 2.8em;
                font-weight: 400;
                color: #fff;
                padding: 0.5em 1em;
                text-transform: uppercase;
            }
        }
    }

    .main-text{
        .text{
            background: $gray-600;
            padding: 2.5em 3em;

            p{
                font-family: 'Oswald', sans-serif !important;
                font-size: 1.5em !important;
                line-height: 1.5em !important;
                font-weight: 400;
                color: #fff;
            }
        }
    }

    form{
        .row{
            font-family: 'Montserrat', sans-serif;
            font-size: 1em !important;
            padding-left: 1em;
        }
        .title{
            background: $gray-100;
            font-family: 'Oswald', sans-serif !important;
            font-size: 1.5em !important;
            padding-left: 1em;
            
            .selected{
                font-family: 'Montserrat', sans-serif!important;
                font-size: 0.6em !important;
                font-weight: 200;
                color: $gray-300;
            }
        }

        .ico-plus{
            color: $secondary;

        }
        .file{
            color: $secondary;
        }

        textarea,
        input{
            border: 1px solid $gray-300;
            border-radius: 0;
            font-family: 'Montserrat', sans-serif!important;
            font-size: 1em !important;
            color: $gray-600;
            min-height: 4em!important;

            &::placeholder{
                color: $gray-400 !important;
            }
            &:focus{
                border: 1px solid $gray-300;
                box-shadow: none;
            }
        }

        button{
            font-family: 'Oswald', sans-serif;
            font-size: 14px !important;
            font-weight: 400;

            color: $secondary;
            background: $white;
            border: 2px solid $secondary;

            &:hover{
                background: $gray-100;
            }

            &.shadow{
                box-shadow: $shadow;
            }
        }
    }

    .new-search{
        color: $secondary;
        text-decoration: none;

        &:hover{
            color: $gray-300;
        }
    }


    .results{
        .title{
            &.green{
                font-family: 'Oswald', sans-serif;
                font-size: 2em;
                font-weight: 400;
                padding: 0.5em 0.8em;
                text-transform: uppercase;
                background: #e7f2d3;
            }
        }
        .result{
            color: $gray-600;
            font-family: 'Oswald', sans-serif;
            font-size: 1.2em;
            font-weight: 400;
            line-height: 2em;
        }

        #accordion{
            margin: 0;
            padding: 0;
            width: 100%;

            .card{
                .card-header{
                    margin-top: 1em;
    
                    button{
                        position: relative;
                        display: inline-block;
                        width: 100%;
                        border: none;
                        background: $gray-200;
                        text-align: left;
                        padding: 0.3em 0;
    
                        color: $gray-600;
                        font-family: 'Oswald', sans-serif;
                        font-size: 1.5em;
                        font-weight: 400;
                        line-height: 2em;
    
                        &:before, &:after {
                            position: absolute;
                            top: 50%;
                            right: 2%;
                            display: flex;
                            align-self: center;
                            justify-content: center;
    
                            content: '';
    
                            height: 2px;
                            width: 12px;
                            background: $secondary;
                        }
                        
                        &:after {
                            transform: rotate(-90deg);
                            transition: all .1s ease-in;
                        }
    
    
                        &:not(.collapsed) {
                            &:after {
                                transform: rotate(0deg);
                                opacity: .0;
                                transition: all .15s ease-out;
                            }
                        }
                    }
                }
                .card-body{
                    i{
                        color: $secondary;
                        font-size: 1.3em;
                        margin-left: 2%;
                    }
                }
            }
        }
    }


    #sidebar-notes{
        background: none !important;

        .row{
            font-family: 'Oswald', sans-serif;
            font-size: 1.2em !important;

            i{
                color: $secondary;
            }
        }

        .title{
            background: $gray-100;
            font-family: 'Oswald', sans-serif !important;
            font-size: 1.5em !important;
            text-transform: uppercase;
        }
    }
}


// MEDIA QUERYS *************************************************
@include media-breakpoint-up(xs) {
    .edu{
        .main-image{
            .overlay-bottom{   
                .title{
                    font-size: 1.6em;
                    padding: 0.2em 1.5em;
                }
            }
        }
    
        .main-text{
            .text{
                padding: 1em 2.5em;
    
                p{
                    font-size: 1em !important;
                    line-height: 1em !important;
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .edu{
        .main-image{
            .overlay-bottom{   
                .title{
                    font-size: 1.8em;
                    padding: 0.5em 1.5em;
                }
            }
        }
    
        .main-text{
            .text{
                padding: 2em 2.8em;
    
                p{
                    font-size: 1em !important;
                    line-height: 1em !important;
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .edu{
        .main-image{
            .overlay-bottom{   
                .title{
                    font-size: 2em;
                    padding: 0.5em 1.5em;
                }
            }
        }
    
        .main-text{
            .text{
                padding: 2em 3em;
    
                p{
                    font-size: 1.2em !important;
                    line-height: 1.2em !important;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .edu{
        .main-image{
            .overlay-bottom{   
                .title{
                    font-size: 2.2em;
                    padding: 0.5em 1.2em;
                }
            }
        }
    
        .main-text{
            .text{
                padding: 2.5em 3em;
    
                p{
                    font-size: 1.2em !important;
                    line-height: 1.2em !important;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .edu{
        .main-image{
            .overlay-bottom{   
                .title{
                    font-size: 2.8em;
                    padding: 0.5em 1em;
                }
            }
        }
    
        .main-text{
            .text{
                padding: 2.5em 3em;
    
                p{
                    font-size: 1.5em !important;
                    line-height: 1.5em !important;
                }
            }
        }
    }
}