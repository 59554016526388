/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */

#food{
    // SEARCH
    #search{
        input[type="text"]{
            background: transparent;
            width: 100%;
            min-height: 50px;
            border: none;
            border-bottom: 2px solid $gray-400;
            font-family: 'Oswald', sans-serif;
            font-size: 1.2em;
            font-weight: 200;         
            color: $gray-600;
            padding-left: 1em;
            padding-right: 1em;

            &.placeholder{
                font-family: 'Oswald', sans-serif;
                font-size: 1.2em;
                font-weight: 600 !important;
            }

        }
        &:before{
            position: absolute;
            top: 0;
            right: 8%;
            display: flex;
            align-items: center;
            justify-content: center;
            
            font-family: 'mladismo';
            color: $secondary;
            font-size: 1.5em;
            content: '\e92d';
            height: 100%;
        }
    }


    // accordion menu
    #accordion{
        .card{
            .card-header{
                margin-top: 1em;

                button{
                    position: relative;
                    display: inline-block;
                    width: 100%;
                    border: none;
                    background: transparent;
                    text-align: left;
                    padding: 0.3em 0;

                    color: $gray-500;
                    font-family: 'Oswald', sans-serif;
                    font-size: 1.2em;
                    font-weight: 400;
                    line-height: 2em;

                    &:before, &:after {
                        position: absolute;
                        top: 43%;
                        right: 0%;
                        display: flex;
                        align-self: center;
                        justify-content: center;

                        content: '';

                        height: 2px;
                        width: 12px;
                        background: $secondary;
                    }
                    
                    &:after {
                        transform: rotate(-90deg);
                        transition: all .1s ease-in;
                    }


                    &:not(.collapsed) {
                        &:after {
                            transform: rotate(0deg);
                            opacity: .0;
                            transition: all .15s ease-out;
                        }
                    }
                }
            }
            .card-body{
                i{
                    color: $secondary;
                    font-size: 1.3em;
                    margin-left: 2%;
                }
            }
        }
    }

    #left{
        // search menu

        #select-filters{
            .filter{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                color: $gray-500;
                font-family: 'Oswald', sans-serif;
                font-size: 1em;
                line-height: 1em;
                font-weight: 400;
                text-transform: uppercase;
                margin-bottom: 0.8em;

                span{
                    width: 90%;
                    text-align: right;
                    padding-right: 5%;
                }

                .btn-filter{
                    height: 2.2em;
                    width: 4em;
                    min-width: 4em;
                    background: #d0d0d0;
                    border: none;
                    border-radius: 3px;
                    color: $white;
                    font-family: 'mladismo';
                    font-size: 1em !important;
                    // margin-left: 1em;

                    &::after{
                        content:'\e955';
                        display: block;
                        float: right;
                    }

                    &.active{
                        background: $secondary;
                        
                        &::after{
                            content:'\e956';
                            float: left;
                        }
                    }
                }
            }
        }


        span{
            &.title{
                display: block;
                clear: both;
                color: $gray-500;
                font-family: 'Oswald', sans-serif;
                font-size: 1.2em;
                font-weight: 400;
                line-height: 2em;
            }

            &.useful-info{
                color: $white;
                font-family: 'Playfair Display', sans-serif;
                font-size: 1em;
                line-height: 2em;
            }
        }
    }

    #right{
        overflow-y: scroll;
        max-height: 1px;
        
        &.inner-shadow{
            box-shadow: inset 0 0 10px rgba($black, 0.2);
        }

        .block-food{
            margin-bottom: $gutter*0.5;

            &.light{
                background: $gray-100;

                .title,
                .location
                .description,
                .details{
                    color: $gray-600;
                }

                .details{
                    background: $gray-200;
                    padding-top: 0.5em;
                    padding-bottom: 0.5em;

                    i{
                        color: $secondary;
                        margin: 0 0.8em;
                    }
                }
            }
            &.dark{
                background: $gray-600;
                .title,
                .location,
                .description,
                .details{
                    color: $white;
                }

                .details{
                    background: $black;
                    padding-top: 0.5em;
                    padding-bottom: 0.5em;

                    i{
                        color: $secondary;
                        margin: 0 0.8em;
                    }
                }
            }

            .title,
            .location{
                font-family: 'Oswald', sans-serif;
                font-size: 1.5em;
                font-weight: 400;
                // line-height: 2em;
                text-transform: uppercase;

                i{
                    color: $secondary;
                }
            }
            .description,
            .details{
                font-family: 'Playfair Display', sans-serif;
                color: $gray-500;
                font-size: 1em;
                font-weight: 200;
            }
            .rating{
                i{
                    color: $gray-500 !important;

                    &.active{
                        color: $secondary !important;
                    }
                }
            }
        }
    }



    // LABELS
    label{
        font-family: 'Oswald', sans-serif;
        color: $gray-500;
        font-size: 1.2em;
        font-weight: 200;
    }

    // INPUTS

    #left{
        input[type="text"],
        input[type="date"],
        input[type="email"],
        input[type="password"]{
            font-family: 'Oswald', sans-serif;
            font-size: 1.2em !important;
            line-height: 2.5em;
            font-weight: 200;
            color: $gray-400;
            border: 1px solid $gray-400 !important;
            border-radius: 2px;
            background: transparent;

            &.small{
                width: 25%;
                margin: 1em;
            }

            &::placeholder{
                color: $gray-400 !important;
            }
            &:focus{
                box-shadow: none;
            }
        }
    }

    // BUTTONS
    button{
        font-family: 'Oswald', sans-serif;
        font-size: 1em !important;
        font-weight: 400;
        border-radius: 6px;
        background: transparent;
        width: 100%;
        height: 4.5em;
        text-transform: uppercase;

        &.green{
            color: $secondary;
            background: transparent;
            border: 2px solid $secondary;

            &:hover{
                background: $white !important;
            }
        }
        &.white{
            color: $white !important;
            background: transparent !important;
            border: 2px solid $white !important;

            &:hover{
                background: rgba($white, 0.1) !important;
            }
        }

        &.shadow{
            box-shadow: $shadow;
        }
    }
    
    .input-group{
        .input-group-prepend{
            .input-group-text{
                color: $secondary;
                border-bottom: 2px solid $gray-400 !important;
            }
        }
        .form-extra{
            a{
                color: $gray-400 !important;
            }
        }
    }

    // CHECKBOXES
    .checkbox-group{
        .checkbox-label{
            font-family: 'Oswald', sans-serif;
            color: $gray-500;
            font-size: 1.2em;
            font-weight: 200;
        }
    }

    input[type=checkbox] { display:none !important; }
    input[type=checkbox] + label:before {
        font-family: mladismo;
        display: inline-block;
        color: $secondary;
    }

    input[type=checkbox] + label:before { 
        content: "\e91e";
        letter-spacing: 10px;
    }

    input[type=checkbox]:checked + label:before {
        content: "\e91d" !important;
    }


}

// MEDIA QUERYS *************************************************
@include media-breakpoint-up(xs) {

}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}