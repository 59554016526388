/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */

 form{
    //  COLORS
    &.normal{
        // INPUTS
        input[type="text"],
        input[type="date"],
        input[type="email"],
        input[type="password"]{
            font-family: 'Oswald', sans-serif;
            font-size: 14px !important;
            font-weight: 200;

            color: $gray-400;
            border-bottom: 2px solid $gray-400 !important;

            &::placeholder{
                color: $gray-400 !important;
            }
            &:focus{
                box-shadow: none;
            }
        }

        // BUTTONS
        button{
            font-family: 'Oswald', sans-serif;
            font-size: 14px !important;
            font-weight: 400;

            color: $secondary;
            background: $white;
            border: 2px solid $secondary;
            
            &:hover{
                background: $gray-100;
            }

            &.shadow{
                box-shadow: $shadow;
            }
        }
        .input-group{
            .input-group-prepend{
                .input-group-text{
                    color: $secondary;
                    border-bottom: 2px solid $gray-400 !important;
                }
            }
            .form-extra{
                a{
                    color: $gray-400 !important;
                }
            }
        }

        // INPUTS
        textarea{
            border: 2px solid $gray-400 !important;
            color: $gray-500;

            &::placeholder{
                color: $gray-400 !important;
            }
            &:focus{
                box-shadow: none;
            }
        }

        // CHECKBOXES
        .checkbox-group{
            .checkbox-label{
                color: $gray-500;
            }
        }

        input[type=checkbox] + label:before {
            color: $secondary;
        }

        // RADIOS
        .form-check{
            .form-check-label{
                color: $gray-500;
                font-family: 'Oswald', sans-serif;
                font-size: 1.2em;
                font-weight: 400;
                line-height: 2em;
                // text-transform: uppercase;

            }
        }

        input[type=radio] + label:before { 
            color: $secondary;
        }
    }
    &.green{
        // INPUTS
        input[type="text"],
        input[type="date"],
        input[type="email"],
        input[type="password"]{
            font-family: 'Oswald', sans-serif;
            font-size: 14px !important;
            font-weight: 200;

            color: $secondary;
            border-bottom: 2px solid $secondary !important;

            &::placeholder{
                color: $secondary !important;
            }
            &:focus{
                box-shadow: none;
            }
        }

        // BUTTONS
        button{
            font-family: 'Oswald', sans-serif;
            font-size: 14px !important;
            font-weight: 400;

            color: $secondary;
            background: $white;
            border: 2px solid $secondary;

            &:hover{
                background: $gray-100;
            }

            &.shadow{
                box-shadow: $shadow;
            }
        }
        .input-group{
            .input-group-prepend{
                .input-group-text{
                    color: $secondary;
                    border-bottom: 2px solid $secondary !important;
                }
            }
            .form-extra{
                a{
                    color: $secondary !important;
                }
            }
        }

        // INPUTS
        textarea{
            border: 2px solid $secondary !important;
            color: $secondary;

            &::placeholder{
                color: $secondary !important;
            }
            &:focus{
                box-shadow: none;
            }
        }

        // CHECKBOXES
        .checkbox-group{
            .checkbox-label{
                color: $gray-500;
            }
        }

        input[type=checkbox] + label:before {
            color: $secondary;
        }

        // RADIOS
        .form-check{
            .form-check-label{
                color: $secondary;
            }
        }

        input[type=radio] + label:before { 
            color: $secondary;
        }
    }
    &.gray{
        // INPUTS
        input[type="text"],
        input[type="date"],
        input[type="email"],
        input[type="password"]{
            font-family: 'Oswald', sans-serif;
            font-size: 14px !important;
            font-weight: 200;

            color: $gray-400;
            border-bottom: 2px solid $gray-400 !important;

            &::placeholder{
                color: $gray-400 !important;
            }
            &:focus{
                box-shadow: none;
            }
        }

        // BUTTONS
        button{

            font-family: 'Oswald', sans-serif;
            font-size: 14px !important;
            font-weight: 400;

            color: $gray-400;
            background: $white;
            border: 2px solid $gray-400;
            
            &:hover{
                background: $gray-100;
            }

            &.shadow{
                box-shadow: $shadow;
            }
        }
        .input-group{
            .input-group-prepend{
                .input-group-text{
                    color: $gray-400;
                    border-bottom: 2px solid $gray-400 !important;
                }
            }
            .form-extra{
                a{
                    color: $gray-400 !important;
                }
            }
        }

        // INPUTS
        textarea{
            border: 2px solid $gray-400 !important;
            color: $gray-400;

            &::placeholder{
                color: $gray-400 !important;
            }
            &:focus{
                box-shadow:none;
            }
        }

        // CHECKBOXES
        .checkbox-group{
            .checkbox-label{
                color: $gray-400;
            }
        }

        input[type=checkbox] + label:before {
            color: $gray-400;
        }

        // RADIOS
        .form-check{
            .form-check-label{
                color: $gray-400;
            }
        }

        input[type=radio] + label:before { 
            color: $gray-400;
        }
    }



    &.pink{
        // INPUTS
        input[type="text"],
        input[type="date"],
        input[type="email"],
        input[type="password"]{
            font-family: 'Oswald', sans-serif;
            font-size: 14px !important;
            font-weight: 200;

            color: $white;
            border: 0 !important;
            border-bottom: 2px solid $white !important;

            &::placeholder{
                color: $white !important;
            }
            &:focus{
                box-shadow: none;
            }
        }

        // BUTTONS
        button{

            font-family: 'Oswald', sans-serif;
            font-size: 14px !important;
            font-weight: 400;

            color: $gray-400;
            background: $white;
            border: 2px solid $gray-400;
            
            &:hover{
                background: $gray-100;
            }

            &.shadow{
                box-shadow: $shadow;
            }
        }
        .input-group{
            .input-group-prepend{
                .input-group-text{
                    color: $gray-400;
                    border-bottom: 2px solid $gray-400 !important;
                }
            }
            .form-extra{
                a{
                    color: $gray-400 !important;
                }
            }
        }

        // INPUTS
        textarea{
            border: 2px solid $gray-400 !important;
            color: $gray-400;

            &::placeholder{
                color: $gray-400 !important;
            }
            &:focus{
                box-shadow:none;
            }
        }

        // CHECKBOXES
        .checkbox-group{
            .checkbox-label{
                color: $gray-400;
            }
        }

        input[type=checkbox] + label:before {
            color: $gray-400;
        }

        // RADIOS
        .form-check{
            .form-check-label{
                color: $gray-400;
            }
        }

        input[type=radio] + label:before { 
            color: $gray-400;
        }
    }



    .input-group{
        .input-group-prepend{
            .input-group-text{
                background-color: transparent !important;
                border: none !important;
                border-bottom: 2px solid $secondary !important;
                border-radius: 0;
            }
        }
        .form-extra{
            width: 100%;
            text-align: right;

            a{
                font-family: 'Oswald', sans-serif;
                font-weight: 200;
                color: $gray-400 !important;
            }
        }
    }

    // INPUTS
    input[type="text"],
    input[type="date"],
    input[type="email"],
    input[type="password"]{
        height: $spacer*5;
        background: none;
        border-top: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
        border-radius: 0;
        font-family: 'Oswald', sans-serif;
        font-size: 1.2em;
        font-weight: 200;

        &::placeholder{
            display: block;
            font-family: 'Oswald', sans-serif;
            font-weight: 200;
            line-height: 2em;
        }
        &:focus{
            background: transparent;
        }
    }

    // TEXTAREA
    textarea{
        background: none;
        border-radius: 0!important;
        font-family: 'Oswald', sans-serif;
        font-size: 1.2em;
        font-weight: 200;

        &::placeholder{
            display: block;
            font-family: 'Oswald', sans-serif;
            font-weight: 200;
            line-height: 2em;
        }
        &:focus{
            background: transparent;
        }
    }

    // BUTTONS
    button{
        display: block;
        width: 100%;
        padding: $spacing*0.5;
        background: none;
        border-radius: 5px;
        font-family: 'Oswald', sans-serif;
        font-size: 1em;
        font-weight: 400;
        text-transform: uppercase;
        cursor: pointer;
    }


    // CHECKBOXES
    .checkbox-group{
        .checkbox-label{
            font-family: 'Oswald', sans-serif;
            color: $gray-500;
            font-size: 1.2em;
            font-weight: 200;
        }
    }

    input[type=checkbox] { display:none !important; }
    input[type=checkbox] + label:before {
        font-family: mladismo;
        display: inline-block;
    }

    input[type=checkbox] + label:before { 
        content: "\e91e";
        letter-spacing: 10px;
    }

    input[type=checkbox]:checked + label:before {
        content: "\e91d" !important;
    }


    // RADIOS
    .form-check{
        .form-check-label{
            font-family: 'Oswald', sans-serif;
            color: $gray-500;
            font-size: 1.2em;
            font-weight: 200;
            cursor: pointer;
        }
    }

    input[type=radio] { display:none !important; }
    input[type=radio] + label:before {
        cursor: pointer;
        font-family: mladismo;
        display: inline-block;
        content: "\e920";
        letter-spacing: 8px;
    }

    input[type=radio]:checked + label:before {
        content: "\e91f";
    }





    // SELECTS
    .select-wrapper{
        padding-right: 2% !important;
        height: 4em;

        .select-hidden {
            display: none !important;
            visibility: hidden !important;
            // padding-right: 10px;
        }
        .select {
            cursor: pointer;
            display: inline-block;
            position: relative;
            width: 100%;
            height: 4em;
            border: 1px solid $gray-400;
            border-radius: 3px;

            user-select: none;
        }
        .select-styled {
            position: absolute; 
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding-left: 1em;

            font-family: 'Montserrat', sans-serif;
            color: $gray-600;
            font-size: 1em;
            line-height: 4em;

            @include transition(all 0.2s ease-in);
            
            &:after {
                position: absolute;
                right: 10px;
                font-family: 'mladismo';
                font-size: 0.8em;
                content:"\e924";
                color: $secondary;
            }
            &:hover {
                background-color: darken($gray-100, 1);
            }
            &:active, &.active {
                background-color: darken($gray-100, 1);
            }
        }

        .select-options {
            display: none; 
            position: absolute;
            top: 100%;
            right: 0;
            left: 0;
            z-index: 999;
            margin: 0;
            padding: 0;
            list-style: none;
            background-color: $white;
            border: 1px solid $gray-300;
            
            li {
                margin: 0;
                margin-top: -1px;
                padding: 12px 0;
                font-family: 'Montserrat', sans-serif;
                color: $gray-600;
                font-size: 1em;
                line-height: 2em;
                text-indent: 15px;
                border-top: 1px solid $gray-300;
                @include transition(all 0.15s ease-in);
                &:hover {
                    background: $gray-100;
                }
                &[rel="hide"] {
                    display: none;
                }
            }
        }
    }
}
    

// MEDIA QUERYS *************************************************
@include media-breakpoint-up(xs) {
    form{
        .select-wrapper{
            padding-right: 0 !important;
            margin-bottom: 1em !important;
            
        }
    }
}

@include media-breakpoint-up(sm) {
    
}

@include media-breakpoint-up(md) {
    form{
        .select-wrapper{
            padding-right: 2% !important;
            margin-bottom: 0 !important;
            
        }
    }
}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}