/* 
 * --------------------------------------------------------------------------
 * Author: AVEO - Multimedijske storitve, Matej Šranc s.p.
 * Website: www.aveo.si
 * --------------------------------------------------------------------------
 *
 * This file is a part of a theme fot Mladismo.si
 *
 *
 */

 /*
// font-family: 'Playfair Display', serif;
// font-family: 'Oswald', sans-serif;
// font-family: 'Montserrat', sans-serif;
 */

 html, body {
     margin: 0;
     padding: 0;
     font-family: 'Playfair Display', sans-serif;
     font-size: 12px;
     color: $gray-600;
     overflow-x: hidden;
 }

.no-padding {
    padding:0px;
}

.no-margin {
    margin:0px;
}

.no-select{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

// override margin and padding
.px-1{
    padding-left: $spacer*0.25 !important;
    padding-right: $spacer*0.25 !important;
}
.mb-2{
    margin-bottom: $spacer*0.8 !important;
}
.mb-5{
    margin-bottom: $spacer*2 !important;
}

// nav-link icon fix
.nav{
    .nav-item{
        .nav-link{
            display: flex;
            align-items: center !important;
        }
    }
}

// content overall
header{
    z-index: 100;
}
footer{
    z-index: 120;
}
#body {
    background-image: url("../assets/img/content-bg.png"), url("../assets/img/content-bg.png");
    background-repeat: repeat-x;
    padding-bottom: 5em;
    z-index: 1;
    min-height:55vh;
    background-position: bottom;

    &.bg-top{
        background-position: top, bottom !important;
    }
 }

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none !important;
}

button{
    cursor: pointer !important;
}


 // modal
 .modal-content{
     z-index: 10 !important;
 }


 // BACKGROUNDS
.bg-1{
    background: $white !important;
}
.bg-2{
    background: $gray-100 !important;
}
.bg-3{
    background: $gray-200 !important;
}
.bg-4{
    background: $gray-400 !important;
}
.bg-5{
    background: $gray-600 !important;
}
.bg-primary{
    background: $primary !important;
}
.bg-secondary{
    background: $secondary !important;
}


// POPOVERS
.popover{
    border: none !important;
    background: $primary;

    .arrow{
        &:before{
            border: none !important;
        }
        &:after{
            border-right-color: $primary;
        }
    }
    
    .popover-body{
        color: $white;
        font-family: 'Montserrat', sans-serif;
        font-size: 0.9em;
    }
}




// MEDIA QUERYS *************************************************
@include media-breakpoint-up(xs) {
    body{
        margin-top: 96px;
     }
}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {
    body{
        margin-top: 167px;
     }
}

@include media-breakpoint-up(lg) {
    body{
        margin-top: 132px;
     }
}

@include media-breakpoint-up(xl) {

}